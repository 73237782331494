import React, { useContext, useEffect } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Loader from '@src/components/Loader';
import Portal from '@src/components/Portal';
import { ServiceWorkerContext } from '@src/utils/contexts';
import styles from './updateAlert.module.scss';

const UpdateAlert = () => {
  const { isUpdating } = useContext(ServiceWorkerContext);

  useEffect(() => {
    if (isUpdating) {
      disableBodyScroll();

      return () => {
        enableBodyScroll();
      };
    }
  }, [isUpdating]);

  return (
    <Portal to="modal-root" mounted={isUpdating}>
      <div
        id="update-alert"
        className={styles.alert}
        role="alert"
        aria-label="Update available"
        aria-describedby="update-alert-description">
        <p id="update-alert-description" className={styles.alertText}>
          Updating...
        </p>
        <Loader className={styles.alertLoader} label="" />
      </div>
    </Portal>
  );
};

export default UpdateAlert;
