import { useState } from '@upstatement/react-hooks';
import { TimelineLite } from 'gsap/gsap-core';

/**
 * Custom React hook for defining transitions for use with the `Transition`
 * component.
 *
 * @example
 * ```jsx
 * const FadeTransitions = useTransition({
 *   onEnter(timeline, screen, isAppearing) {
 *     timeline.fromTo(screen, 0.2, { opacity: 0 }, { opacity: 1 });
 *   },
 *   onEnter(timeline, screen) {
 *     timeline.fromTo(screen, 0.2, { opacity: 1 }, { opacity: 0 });
 *   },
 * });
 *
 * return (
 *   <Transition timeout={200} onEnter={FadeTransitions.onEnter} onExit={FadeTransitions.onExit}>
 *     {...}
 *   </Transition>
 * )
 * ```
 *
 * @param {object} options the transition options
 * @param {(timeline: TimelineLite, screen: HTMLElement, isAppearing: boolean) => void} options.onEnter the transition `onEnter` callback
 * @param {(timeline: TimelineLite, screen: HTMLElement) => void} options.onExit the transition `onExit` callback
 *
 * @returns {{ isTransitioning: boolean, onEnter(screen: HTMLElement, isAppearing: boolean): void, onExit(screen: HTMLElement): void}}
 */
const useTransition = ({ onEnter, onExit }) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  return {
    isTransitioning,
    onEnter(screen, isAppearing) {
      const timeline = new TimelineLite({
        onStart: () => setIsTransitioning(true),
        onComplete: () => setIsTransitioning(false),
      });
      onEnter(timeline, screen, isAppearing);
    },
    onExit(screen) {
      const timeline = new TimelineLite({
        onStart: () => setIsTransitioning(true),
        onComplete: () => setIsTransitioning(false),
      });
      onExit(timeline, screen);
    },
  };
};

export default useTransition;
