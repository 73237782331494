import React, { useContext, useEffect, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import BuildingData from '@src/data/locations.json';
import Help from '@src/components/Modals/Help';
import Pagination from '@src/components/Pagination';
import Portal from '@src/components/Portal';
import SEO from '@src/components/SEO';
import Transition from '@src/components/Transition';
import useAnnounce from '@src/hooks/useAnnounce';
import RegionList from './RegionList';
import Buildings from './Buildings';
import useSlidingTransition from '@src/hooks/useSlidingTransition';
import { Arrow } from '@src/svgs';
import { className } from '@src/utils';
import { TransitionContext } from '@src/utils/contexts';
import styles from './locations.module.scss';

function unique(arr) {
  const list = new Set();
  return arr.filter(item => {
    if (!list.has(item.name)) {
      list.add(item.name);
      return true;
    }
    return false;
  });
}

const Locations = () => {
  const locationsMainRef = useRef(null);

  const [regions, setRegions] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [shouldShowHelp, setShouldShowHelp] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const [transitionState] = useContext(TransitionContext);
  const helpButtonRef = useRef(null);

  const shouldShowBuildings = filteredLocations.length > 0;

  useAnnounce(() => {
    if (shouldShowBuildings) {
      return 'Building Selection';
    }
    return 'Region Selection';
  }, [shouldShowBuildings]);

  const ScreenTransitions = useSlidingTransition({
    getTranslateX() {
      return shouldShowBuildings ? 10 : -10;
    },
    onExited() {
      // Refocus the page and scroll to the top
      locationsMainRef.current.focus();
      window.scrollTo(0, 0);
    },
  });

  const goBack = () => {
    setSelectedRegion(null);
    setFilteredLocations([]);
  };

  /**
   * Closes the help modal, and refocuses the help button trigger.
   */
  const closeHelpModal = () => {
    setShouldShowHelp(false);
    helpButtonRef.current.focus();
  };

  const onItemClick = region => {
    setSelectedRegion(region.name);
    const filteredLocs = BuildingData.filter(loc => loc.fields.Region === region.name);

    setFilteredLocations(filteredLocs);
  };

  useEffect(() => {
    const regions = BuildingData.map(location => ({
      name: location.fields.Region,
    }));

    setRegions(unique(regions));
  }, []);

  return (
    <div className={styles.locations}>
      <SEO title="Locations" />
      <Portal
        className={styles.locationsNav}
        to="header-root"
        prepend
        mounted={transitionState === 'entering' || transitionState === 'entered'}>
        <div className={styles.locationsNavInner}>
          <div className={styles.locationsNavItem}>
            {shouldShowBuildings && (
              <button
                className={styles.locationsNavButton}
                type="button"
                onClick={goBack}
                aria-label="Go back">
                <i>
                  <Arrow />
                </i>
                Back
              </button>
            )}
          </div>
          <div className={styles.locationsNavItem}>
            <Pagination max={2} current={Math.abs(shouldShowBuildings)} />
          </div>
          <div className={styles.locationsNavItem}>
            <button
              ref={helpButtonRef}
              type="button"
              aria-controls="help-modal"
              aria-label="Open help modal"
              className={styles.locationsNavHelpLink}
              onClick={() => setShouldShowHelp(true)}>
              Help
            </button>
            <Help id="help-modal" shouldDisplay={shouldShowHelp} close={closeHelpModal} />
          </div>
        </div>
      </Portal>
      <div
        ref={locationsMainRef}
        {...className(
          styles.locationsMain,
          ScreenTransitions.isTransitioning && styles.locationsMainWithTransition,
        )}
        tabIndex={-1}>
        <TransitionGroup component={null}>
          <Transition
            key={shouldShowBuildings}
            appear
            unmountOnExit
            timeout={ScreenTransitions.timeout}
            onEnter={ScreenTransitions.onEnter}
            onExit={ScreenTransitions.onExit}>
            <div className={styles.locationsScreen}>
              {shouldShowBuildings ? (
                <Buildings region={selectedRegion} filteredLocations={filteredLocations} />
              ) : (
                <RegionList regions={regions} onItemClick={onItemClick} />
              )}
            </div>
          </Transition>
        </TransitionGroup>
      </div>
    </div>
  );
};

export default Locations;
