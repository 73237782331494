import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styles from './regionList.module.scss';

const RegionList = ({ regions, onItemClick }) => (
  <Fragment>
    <header>
      <h1 className={styles.locationsTitle}>Locations</h1>
      <h2 className={styles.locationsQuestion}>Where are you checking&nbsp;in&nbsp;today?</h2>
    </header>
    <ul className={styles.locationsList}>
      {regions.map(region => (
        <li key={region.name} className={styles.locationsListRow}>
          <button className={styles.locationsListItem} onClick={() => onItemClick(region)}>
            <span>{region.name}</span>
          </button>
        </li>
      ))}
    </ul>
  </Fragment>
);

RegionList.propTypes = {
  regions: PropTypes.array,
  onItemClick: PropTypes.func,
};

export default RegionList;
