import { useContext, useEffect } from 'react';
import { AnnouncerContext } from '@src/utils/contexts';

/**
 * Custom React hook for updating the parent announcer with the latest screen title.
 *
 * @param {() => string} getTitle a callback to get the current screen title
 * @param {array} [dependencies=[]] a dependency array for the announce effect
 */
const useAnnounce = (getTitle, dependencies = []) => {
  const announcer = useContext(AnnouncerContext);

  useEffect(() => {
    const screenTitle = getTitle();
    if (screenTitle) {
      announcer.onScreenChange(screenTitle);
    }
  }, dependencies);
};

export default useAnnounce;
