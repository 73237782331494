import React, { useRef, useState } from 'react';
import SEO from '@src/components/SEO';
import { IS_DEVICE } from '@src/utils/constants';
import TempFAQ from '@src/components/Modals/Temp/FAQ';
import TempFailure from '@src/components/Modals/Temp/Failure';
import styles from './tempCheck.module.scss';

const TempCheck = () => {
  const { android } = IS_DEVICE;

  const [shouldShowModal, setShouldShowModal] = useState(false);
  const [showShowFailModal, setShowShowFailModal] = useState(false);
  const getAppRef = useRef(null);

  const closeModal = () => {
    setShouldShowModal(false);
    getAppRef.current.focus();
  };

  const closeFailModal = () => {
    setShowShowFailModal(false);
    getAppRef.current.focus();
  };

  const appLink = android
    ? 'https://play.google.com/store/apps/details?id=com.beaconcp.screener&hl=en_US'
    : 'https://apps.apple.com/us/app/id1527096004';

  return (
    <div className={styles.page}>
      <SEO title="Temp Check" />
      <section className={styles.mainContent}>
        <header className={styles.header}>
          <h1 className={styles.headerLabel}>Almost There...</h1>
          <h1>Please Proceed to the Temperature Check</h1>
        </header>
        <p className={styles.description}>
          Before entering the building, you must show that you{' '}
          <button onClick={() => setShouldShowModal(true)} className={styles.pageLink}>
            don’t&nbsp;have&nbsp;a&nbsp;fever
          </button>
          .
        </p>
        <section className={styles.notes}>
          For faster entry:
          <ul className={styles.notesList}>
            <li className={styles.notesItem}>
              Get the{' '}
              <a href={appLink} className={styles.pageLink}>
                Beacon Passport app
              </a>
            </li>
            <li className={styles.notesItem}>Show your daily passport to enter</li>
          </ul>
        </section>
      </section>
      <footer className={styles.footer}>
        <a href={appLink} ref={getAppRef} className={styles.getAppButton}>
          Get App
        </a>
        <section className={styles.footerLinks}>
          <button onClick={() => setShowShowFailModal(true)} className={styles.footerLink}>
            <span className={styles.footerLinkText}>Didn't Pass?</span>
          </button>
          <button onClick={() => setShouldShowModal(true)} className={styles.footerLink}>
            <span className={styles.footerLinkText}>Questions?</span>
          </button>
        </section>
      </footer>
      <TempFAQ id="temp-modal" shouldDisplay={shouldShowModal} close={closeModal} />
      <TempFailure id="temp-fail-modal" shouldDisplay={showShowFailModal} close={closeFailModal} />
    </div>
  );
};

export default TempCheck;
