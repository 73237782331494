import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Power2 } from 'gsap/gsap-core';
import { useLocation } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import Announcer from '@src/components/Announcer';
import ModalNav from '@src/components/ModalNav';
import Onboarding from '@src/components/Onboarding';
import Splash from '@src/components/Splash';
import Transition from '@src/components/Transition';
import UpdateAlert from '@src/components/UpdateAlert';
import NativeInstallBanner from '@src/components/NativeInstallBanner';
import useCreateAnnouncer from '@src/hooks/useCreateAnnouncer';
import useTransition from '@src/hooks/useTransition';
import useDetectAndroidChrome from '@src/hooks/useDetectAndroidChrome';
import { className } from '@src/utils';
import { usePageView } from '@src/utils/analytics';
import {
  SplashScreenContext,
  ModalNavContext,
  OnboardingContext,
  KioskModeContext,
  GlobalHistoryContext,
} from '@src/utils/contexts';
import styles from './layout.module.scss';

const Layout = ({ children }) => {
  usePageView();

  const globalHistory = useContext(GlobalHistoryContext);

  const modalNav = useContext(ModalNavContext);

  const kioskMode = useContext(KioskModeContext);

  const isAndroidChrome = useDetectAndroidChrome();

  const { hasOnboarded, isOnboardingOpen } = useContext(OnboardingContext);

  const announcer = useCreateAnnouncer('app', !hasOnboarded);
  const pageRef = useRef(null);

  const [splashContext, setSplashContext] = useContext(SplashScreenContext);

  const [nativeInstallBannerVisible, setNativeInstallBannerVisible] = useState();

  /**
   * Event handler for when the splash screen has finished transforming. This
   * toggles the splash screen to no longer show.
   */
  const onSplashComplete = () => {
    setSplashContext(context => ({
      ...context,
      isVisible: false,
    }));
    pageRef.current.focus();
  };

  const DURATION = 0.3;
  const PageTransitions = useTransition({
    onEnter(timeline, screen, isAppearing) {
      if (isAppearing) {
        timeline.set(screen, { position: 'relative', opacity: 1, clearProps: 'overflow' });
        return;
      }
      timeline.delay(isOnboardingOpen ? 0.2 : DURATION);
      timeline.set(screen, {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        height: '100%',
        pointerEvents: 'none',
        clearProps: 'overflow',
        scale: 0.985,
      });
      timeline.to(screen, DURATION, {
        opacity: 1,
        scale: 1,
        ease: Power2.easeOut,
      });
      timeline.set(screen, {
        position: 'relative',
        clearProps: 'height,pointerEvents',
      });
    },
    onExit(timeline, screen) {
      timeline.set(screen, {
        opacity: 1,
        pointerEvents: 'none',
        overflow: 'hidden',
      });
      timeline.to(screen, DURATION, {
        opacity: 0,
        scale: 0.985,
        ease: Power2.easeOut,
      });
      timeline.set(screen, {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
      });
    },
  });

  const location = useLocation();

  useEffect(() => {
    const unlisten = globalHistory.listen(() => {
      pageRef.current.focus();
    });
    return unlisten;
  }, []);

  return (
    <div
      ref={pageRef}
      {...className(
        styles.page,
        isAndroidChrome && 'androidChrome',
        isAndroidChrome && nativeInstallBannerVisible && styles.offsetTop,
      )}
      tabIndex="-1">
      {isAndroidChrome && (
        <NativeInstallBanner
          visibility={nativeInstallBannerVisible}
          setVisibility={setNativeInstallBannerVisible}
        />
      )}

      <Announcer announcer={announcer}>
        <Splash onComplete={onSplashComplete} />
        <a href="#content" target="_self" className={styles.skipToContent}>
          Skip to content
        </a>
        <div
          {...className(
            styles.layout,
            splashContext.isVisible && styles.layoutHidden,
            modalNav.isOpen && styles.layoutMinimized,
          )}>
          <header id="header-root" className={styles.appHeader}>
            <div className={styles.appHeaderInner}>{!kioskMode.isEnabled && <ModalNav />}</div>
          </header>
          <main
            id="content"
            {...className(
              styles.main,
              PageTransitions.isTransitioning && styles.mainWithTransition,
            )}>
            <TransitionGroup component={null}>
              <Transition
                key={`${location.pathname}-${hasOnboarded}`}
                appear
                unmountOnExit
                timeout={DURATION * 1000}
                onEnter={PageTransitions.onEnter}
                onExit={PageTransitions.onExit}>
                <div className={styles.mainContent}>
                  {children && hasOnboarded && children(location)}
                </div>
              </Transition>
            </TransitionGroup>
          </main>
        </div>
        <div id="modal-root" />
        <UpdateAlert />

        {!modalNav.getPaths().includes(globalHistory.location.pathname) && <Onboarding />}
      </Announcer>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.func,
  location: PropTypes.object,
};

export default Layout;
