import React from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import Portal from '@src/components/Portal';
import useSingleModalAnimation from '../useSingleModalAnimation';
import { Arrow } from '@src/svgs';
import styles from './help.module.scss';

const Help = ({ close, shouldDisplay, id }) => {
  const { modalContentRef, closeButtonRef, modalRef, onClose } = useSingleModalAnimation(
    close,
    shouldDisplay,
  );

  return (
    <Portal to="modal-root" mounted={shouldDisplay}>
      <FocusLock disabled={!shouldDisplay} noFocusGuards>
        <div className={styles.container}>
          <div
            id={id}
            className={styles.helpModal}
            role="dialog"
            aria-label="Help Modal"
            aria-modal="true">
            <div ref={modalRef} className={styles.helpModalInner}>
              <header className={styles.helpModalHeader}>
                <button ref={closeButtonRef} className={styles.helpModalClose} onClick={onClose}>
                  <i className={styles.helpModalCloseIcon}>
                    <Arrow />
                  </i>
                  <span className={styles.helpModalCloseText}>Dismiss</span>
                </button>
              </header>
              <div ref={modalContentRef} className={styles.helpModalContent}>
                <h1 id="help-modal-label" className={styles.helpModalHeaderTitle}>
                  Help
                </h1>
                <h2 className={styles.helpModalHeaderDescription}>Having some trouble?</h2>
                <div className={styles.freeText}>
                  <p>
                    Not to worry, we’re here to help! Just{' '}
                    <a href="mailto:appsupport@beaconcapital.com">send us an email</a> or tap the
                    blue button below. Someone from our team will respond shortly.
                  </p>
                </div>
                <div className={styles.linkWrapper}>
                  <a href="mailto:appsupport@beaconcapital.com" className={styles.contactLink}>
                    Contact
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FocusLock>
    </Portal>
  );
};

Help.propTypes = {
  close: PropTypes.func,
  shouldDisplay: PropTypes.bool,
  id: PropTypes.string,
};

export default Help;
