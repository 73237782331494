import { useState } from 'react';

/**
 * Custom React hook for generating an announcer for use with the `Announcer`
 * component.
 *
 * @param {string} id a unique announcer ID
 * @param {boolean} [shouldAnnounceFirstScreen=false] whether to announce the first
 * screen title when it has mounted
 */
const useCreateAnnouncer = (id, shouldAnnounceFirstScreen = false) => {
  const [hasFirstScreenMounted, setHasFirstScreenMounted] = useState(shouldAnnounceFirstScreen);

  const [screenTitle, setScreenTitle] = useState(null);

  /**
   * Handler for when the screen changes. Updates the announcer's current
   * screen title. If the `shouldAnnounceFirstScreen` option is true, this will
   * skip adding the screen title for the first call.
   *
   * @param {string} nextScreenTitle the next screen title
   */
  const onScreenChange = nextScreenTitle => {
    if (!hasFirstScreenMounted) {
      return setHasFirstScreenMounted(true);
    }
    setScreenTitle(nextScreenTitle);
  };

  /**
   * Resets the announcer.
   */
  const reset = () => {
    setScreenTitle(null);
    setHasFirstScreenMounted(shouldAnnounceFirstScreen);
  };

  return { id, onScreenChange, reset, screenTitle };
};

export default useCreateAnnouncer;
