import React from 'react';
import PropTypes from 'prop-types';
import styles from './errorNotice.module.scss';

const ErrorNotice = ({ error, onRetry }) => (
  <div className={styles.page}>
    <header className={styles.pageHeader}>
      <h2 className={styles.title}>
        <span>Uh-oh!</span> Something went wrong.
      </h2>
      {error.status && <h3 className={styles.code}>Code: {error.status}</h3>}
    </header>
    <button className={styles.retryButton} type="button" onClick={onRetry}>
      Try again
    </button>
  </div>
);

ErrorNotice.propTypes = {
  error: PropTypes.object.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default ErrorNotice;
