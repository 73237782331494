import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useSet } from '@upstatement/react-hooks';
import { __RouterContext as RouterContext } from 'react-router';
import { Link as DefaultLink } from 'react-router-dom';
import {
  GlobalHistoryContext,
  KioskModeContext,
  ModalNavHistoryContext,
  ModalNavContext,
} from '@src/utils/contexts';

const Link = ({ children, to, ...props }) => {
  const routerContext = useContext(RouterContext);

  const globalHistory = useContext(GlobalHistoryContext);
  const modalNavHistory = useContext(ModalNavHistoryContext);

  const kioskMode = useContext(KioskModeContext);

  const { getPaths } = useContext(ModalNavContext);

  const modalNavRoutesSet = useSet(getPaths());

  let history = globalHistory;
  if (modalNavRoutesSet.has(to.pathname || to)) {
    history = modalNavHistory;
  }

  let toProp = to;
  if (typeof toProp === 'string') {
    toProp += kioskMode.searchQuery;
  } else {
    toProp.search = kioskMode.searchQuery;
  }

  return (
    <RouterContext.Provider value={{ ...routerContext, history }}>
      <DefaultLink to={toProp} {...props}>
        {children}
      </DefaultLink>
    </RouterContext.Provider>
  );
};

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
};

export default Link;
