import React from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import Portal from '@src/components/Portal';
import useSingleModalAnimation from '../useSingleModalAnimation';
import { Arrow, StopHand } from '@src/svgs';
import styles from './temp.module.scss';

const TempFailure = ({ close, shouldDisplay, id }) => {
  const { modalContentRef, closeButtonRef, modalRef, onClose } = useSingleModalAnimation(
    close,
    shouldDisplay,
  );

  return (
    <Portal to="modal-root" mounted={shouldDisplay}>
      <FocusLock disabled={!shouldDisplay} noFocusGuards>
        <div className={styles.container}>
          <div
            id={id}
            className={styles.tempModal}
            role="dialog"
            aria-label="Help Modal"
            aria-modal="true">
            <div ref={modalRef} className={styles.tempModalInner}>
              <nav>
                <button ref={closeButtonRef} className={styles.tempModalClose} onClick={onClose}>
                  <i className={styles.tempModalCloseIcon}>
                    <Arrow />
                  </i>
                  <span className={styles.tempModalCloseText}>Dismiss</span>
                </button>
              </nav>
              <section ref={modalContentRef} className={styles.tempModalContent}>
                <header className={styles.tempModalHeader}>
                  <div className={styles.tempFailStopHand}>
                    <StopHand />
                  </div>
                  <h1 className={styles.tempFailStopText}>Stop</h1>
                  <h2 className={styles.tempModalHeaderDescription}>
                    Please Do Not Enter the&nbsp;Building&nbsp;Today
                  </h2>
                </header>
                <article className={styles.freeText}>
                  <ul>
                    <li>Based on your temperature, you should contact a medical professional.</li>
                    <li>
                      For more information about coming to work, please talk to your employer.
                    </li>
                    <li>You may screen yourself again in 24 hours.</li>
                  </ul>
                </article>
              </section>
            </div>
          </div>
        </div>
      </FocusLock>
    </Portal>
  );
};

TempFailure.propTypes = {
  close: PropTypes.func,
  shouldDisplay: PropTypes.bool,
  id: PropTypes.string,
};

export default TempFailure;
