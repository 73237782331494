import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import useAnnounce from '@src/hooks/useAnnounce';
import styles from './onboardingRules.module.scss';

const OnboardingRules = ({ onAgree }) => {
  useAnnounce(() => 'Rules Agreement');

  return (
    <Fragment>
      <header className={styles.header}>
        <h1 className={styles.headerTitle}>For everyone&rsquo;s safety</h1>
        <h2 className={styles.headerDescription}>
          Wear a face covering and maintain a 6-foot distance in all common areas.
        </h2>
      </header>
      <div className={styles.illustrationWrapper}>
        <figure className={styles.illustrationFigure}>
          <img
            src="/img/onboarding-01.gif"
            alt=""
            width="336"
            height="280"
            className={styles.illustrationImage}
          />
        </figure>
        <button className={styles.ctaButton} onClick={onAgree}>
          Agree
        </button>
      </div>
    </Fragment>
  );
};

OnboardingRules.propTypes = {
  onAgree: PropTypes.func.isRequired,
};

export default OnboardingRules;
