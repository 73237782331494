import React, { useContext, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import Image from '@src/components/Image';
import Loader from '@src/components/Loader';
import PassportCta from '@src/components/PassportCta';
import SEO from '@src/components/SEO';
import useHotjarPageView from '@src/hooks/useHotjarPageView';
import useSplash from '@src/hooks/useSplash';
import {
  FullHeightContext,
  SplashScreenContext,
  SpotColorsContext,
  TransitionContext,
  KioskModeContext,
} from '@src/utils/contexts';
import getMonthAndDay from '@src/utils/dateHelper';
import styles from './success.module.scss';

const getDailySpotColors = day => ({
  main: styles[`var${day}MainColor`],
  secondary: styles[`var${day}SecondaryColor`],
  isLight: JSON.parse(styles[`var${day}IsLight`]),
});

const Success = ({ timestamp, building }) => {
  useHotjarPageView('/success');

  const imageRef = useRef(null);

  const kioskMode = useContext(KioskModeContext);

  const [transitionState] = useContext(TransitionContext);

  const fullHeightContext = useContext(FullHeightContext);
  const [, setSplashContext] = useContext(SplashScreenContext);
  const { setSpotColors, resetSpotColors } = useContext(SpotColorsContext);

  const withSplash = useSplash(Success.SPLASH_ID);

  useEffect(() => {
    if (transitionState === 'entered' && building) {
      const dimensions = imageRef.current.getBoundingClientRect();
      setSplashContext(oldContext => ({
        ...oldContext,
        dimensions,
      }));
    }
  }, [building, transitionState]);

  useEffect(() => {
    if (withSplash && (!fullHeightContext.willUpdate || fullHeightContext.value) && building) {
      const dimensions = imageRef.current.getBoundingClientRect();
      setSplashContext(oldContext => {
        const updatedContext = {
          ...oldContext,
          dimensions,
          isVisible: true,
          hasRunFor: [...oldContext.hasRunFor, Success.SPLASH_ID],
        };
        // Update text if this isn't the first splash screen shown
        if (oldContext.hasRunFor.length) {
          updatedContext.text = `Here's your pass.`;
        }
        return updatedContext;
      });
    }
  }, [building, fullHeightContext, withSplash]);

  useEffect(() => {
    if (building) {
      const day = new Date(timestamp).toLocaleDateString('en-US', { weekday: 'long' }); // Monday, Tuesday, etc.
      setSpotColors(getDailySpotColors(day));
    }
    return resetSpotColors;
  }, [building]);

  if (!building) {
    return (
      <div className={styles.loadingIndicator}>
        <Loader />
      </div>
    );
  }

  // Gets the stored date object
  const date = new Date(timestamp);
  const [month, monthDay] = getMonthAndDay(date);
  const year = date.getUTCFullYear();
  const dateTime = `${year}-${date.getUTCMonth() + 1}-${date.getUTCDate()}`;
  const dateStamp = `${month}.${monthDay}.${year.toString().slice(2)}`;
  // Gets the weekday name (i.e.: "Monday")
  const weekday = date.toLocaleDateString('en-US', { weekday: 'long' });

  return (
    <div className={styles.page}>
      <SEO title="Success" />
      <div className={styles.pageTopper}>
        <header className={styles.pageTopperHeader}>
          <h1 className={styles.title}>
            <span className={styles.titleWeekday}>{weekday}</span>
            <time dateTime={dateTime}>{dateStamp}</time>
          </h1>
          {kioskMode.isEnabled ? (
            <Fragment>
              <p className={styles.description}>Approved for one-time entry.</p>
              <p className={styles.description}>If you return, please sign in again.</p>
            </Fragment>
          ) : (
            <Fragment>
              <p className={styles.description}>Display to enter the building.</p>
              <p className={styles.description}>Expires at midnight.</p>
            </Fragment>
          )}
        </header>
        <figure className={styles.building}>
          <div className={styles.buildingInner}>
            <Image
              ref={imageRef}
              className={styles.buildingInnerImage}
              src={building.fields.Image[0].url}
              alt={`Portrait of ${building.fields.Name}`}
            />
          </div>
        </figure>
      </div>
      <PassportCta bleed />
    </div>
  );
};

Success.SPLASH_ID = 'success';

Success.propTypes = {
  timestamp: PropTypes.number.isRequired,
  building: PropTypes.shape({
    createdTime: PropTypes.date,
    id: PropTypes.string,
    fields: PropTypes.shape({
      Name: PropTypes.string,
      Radius: PropTypes.string,
      Region: PropTypes.string,
      Latitude: PropTypes.string,
      Longitude: PropTypes.string,
      Image: PropTypes.array,
    }),
  }),
};

export default Success;
