import React from 'react';
import styles from '@src/svgs/sharedStyles.module.scss';

const StopHand = () => (
  <svg
    id="stop-hand"
    className={styles.rootTall}
    viewBox="0 0 48 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="stop-hand-title"
    aria-describedby="stop-hand-description">
    <title id="stop-hand-title">Stop hand</title>
    <description id="stop-hand-description">
      A hand with the palm facing toward the viewer, motioning to stop.
    </description>
    <path
      className={styles.fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M43.8562 48.9297C41.5651 59.9561 33.4296 69.3931 21.767 69.3094C9.64273 69.2222 0 59.0443 0 46.92V13.5871C0 12.018 1.31313 10.7558 2.90038 10.8394C4.38875 10.9172 5.50411 12.2529 5.50411 13.7434V31.7521C5.50411 32.7017 6.2741 33.4717 7.22369 33.4717C8.17327 33.4717 8.94326 32.7017 8.94326 31.7521V6.87963C8.94326 5.19858 10.4513 3.86946 12.1883 4.17047C13.5305 4.40387 14.4474 5.67338 14.4474 7.03595V28.5696C14.4474 29.7097 15.3715 30.6338 16.5109 30.6338C17.651 30.6338 18.5744 29.7097 18.5744 28.5696V2.75191C18.5744 1.07087 20.0824 -0.258264 21.8194 0.0427536C23.1623 0.276151 24.0785 1.54566 24.0785 2.90823V28.8277C24.0785 29.8253 24.887 30.6338 25.8846 30.6338C26.8814 30.6338 27.69 29.8253 27.69 28.8277V7.05195C27.69 5.37018 29.1979 4.04105 30.935 4.34279C32.2772 4.57546 33.1941 5.84497 33.1941 7.20755V37.6408C33.1941 38.4755 33.6049 39.2572 34.2934 39.7298L34.3494 39.7676C35.1776 40.286 36.1628 40.3798 37.0353 39.8963C37.8009 39.4724 38.2045 38.6239 38.4749 37.6946C39.2944 34.8779 39.9582 32.1324 41.2023 29.4494C42.0973 27.5197 43.2723 24.6636 45.6085 24.1489C46.5646 23.9387 47.4946 24.5437 47.4946 25.5223L43.8562 48.9297Z"
    />
  </svg>
);

export default StopHand;
