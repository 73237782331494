import React from 'react';
import styles from '@src/svgs/sharedStyles.module.scss';

const HomescreenIcon = () => (
  <svg
    id="homescreen"
    className={styles.rootTall}
    viewBox="0 0 29 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby="homescreen-title">
    <title id="homescreen-title">Homescreen Icon</title>
    <path
      className={styles.fill}
      d="M9.07528 7.90123L12.9478 4.01704V23.545C12.9478 24.1954 13.4745 24.7221 14.1249 24.7221C14.7752 24.7221 15.302 24.1954 15.302 23.545V4.01704L19.1745 7.90123C19.3952 8.12341 19.6968 8.24995 20.0103 8.24995C20.3237 8.24995 20.6253 8.12341 20.846 7.90123C21.0682 7.68053 21.1947 7.37891 21.1947 7.06548C21.1947 6.75206 21.0682 6.45046 20.846 6.22973L14.9606 0.344298C14.8487 0.236887 14.7163 0.153022 14.5721 0.0971097C14.2882 -0.0323699 13.9615 -0.0323699 13.6776 0.0971097C13.5334 0.153022 13.4009 0.236887 13.2891 0.344298L7.40371 6.22973C6.94168 6.69175 6.94168 7.43918 7.40371 7.9012C7.86573 8.36322 8.61315 8.36322 9.07517 7.9012L9.07528 7.90123Z"
    />
    <path
      className={styles.fill}
      d="M4.1198 37.6697H24.1303C26.405 37.6697 28.2503 35.8246 28.2503 33.5497V15.8934C28.2503 14.8002 27.8162 13.7525 27.0438 12.9801C26.2713 12.2076 25.2237 11.7736 24.1305 11.7736H20.0105V14.1277H24.1305C25.106 14.1277 25.8961 14.9179 25.8961 15.8934V33.5497C25.8961 34.5252 25.106 35.3153 24.1305 35.3153H4.11999C3.1445 35.3153 2.35436 34.5252 2.35436 33.5497V15.8934C2.35436 14.9179 3.1445 14.1277 4.11999 14.1277H8.23999V11.7736H4.11999C1.84526 11.7736 0 13.6186 0 15.8936V33.5499C0 35.8246 1.84507 37.6699 4.11999 37.6699L4.1198 37.6697Z"
    />
  </svg>
);

export default HomescreenIcon;
