import { useEffect } from 'react';

const useHotjarPageView = path => {
  useEffect(() => {
    if (window.hj) {
      window.hj('vpv', path);
    }
  }, []);
};

export default useHotjarPageView;
