import React from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';

const SEO = ({ title, children }) => (
  <Helmet>
    <title>Beacon Capital - {title}</title>
    {children}
  </Helmet>
);

SEO.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

export default SEO;
