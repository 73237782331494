import { Power1 } from 'gsap/gsap-core';
import useTransition from '@src/hooks/useTransition';

const DURATION = 0.2;

/**
 * Custom React hook for defining a sliding page transition. This is helpful in multi-step screens
 * that need motion to show the flow of the process.
 *
 * @param {object} options the transition options
 * @param {(isEntering: boolean) => number} options.getTranslateX a function that returns the direction
 * and distance of the slide
 * @param {(screen: HTMLElement, isAppearing: boolean) => void} [options.onEntered] callback for after the
 * screen has entered
 * @param {(screen: HTMLElement) => void} [options.onExited] callback for after the screen has exited
 */
const useSlidingTransition = ({ getTranslateX, onEntered, onExited }) => {
  const SlidingTransitions = useTransition({
    onEnter(timeline, screen, isAppearing) {
      if (!isAppearing) {
        timeline.delay(DURATION);
      }
      const translateX = getTranslateX(true);
      timeline.set(screen, {
        height: 'auto',
        left: 0,
        opacity: isAppearing ? 1 : 0,
        overflow: 'initial',
        pointerEvents: 'none',
        position: 'relative',
        visibility: 'visible',
        width: '100%',
        translateX: isAppearing ? 0 : translateX,
      });
      if (!isAppearing) {
        timeline.to(screen, DURATION, {
          ease: Power1.easeOut,
          opacity: 1,
          translateX: 0,
        });
      }
      timeline.set(screen, {
        clearProps: 'pointerEvents',
      });
      if (onEntered) {
        timeline.then(() => {
          onEntered(screen, isAppearing);
        });
      }
    },
    onExit(timeline, screen) {
      timeline.set(screen, {
        pointerEvents: 'none',
        translateX: 0,
      });
      const translateX = getTranslateX(false);
      timeline.to(screen, DURATION, {
        ease: Power1.easeOut,
        opacity: 0,
        translateX: translateX,
      });
      timeline.set(screen, {
        height: 0,
        left: -5000,
        opacity: 0,
        position: 'absolute',
        visibility: 'hidden',
        overflow: 'hidden',
        width: 0,
      });
      if (onExited) {
        timeline.then(() => {
          onExited(screen);
        });
      }
    },
  });

  return {
    ...SlidingTransitions,
    timeout: DURATION * 1000,
  };
};

export default useSlidingTransition;
