import React, { useContext, useEffect, useRef, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import ErrorNotice from '@src/components/ErrorNotice';
import Loader from '@src/components/Loader';
import Help from '@src/components/Modals/Help';
import Pagination from '@src/components/Pagination';
import Portal from '@src/components/Portal';
import SEO from '@src/components/SEO';
import Form from './Form';
import { Arrow } from '@src/svgs';
import * as Analytics from '@src/utils/analytics';
import {
  FormDataContext,
  KioskModeContext,
  PassportContext,
  TransitionContext,
} from '@src/utils/contexts';
import getQuestionsForBuilding from '@src/utils/get-questions-for-building';
import styles from './questionnaire.module.scss';

const Questionnaire = () => {
  const passport = useContext(PassportContext);

  const { locationName } = useParams();
  const { searchQuery } = useContext(KioskModeContext);

  const history = useHistory();

  const { questions, setQuestions, currentStep, setCurrentStep, formData, updateData } = useContext(
    FormDataContext,
  );

  const [transitionState] = useContext(TransitionContext);

  const helpButtonRef = useRef(null);

  const [questionsLoading, setQuestionsLoading] = useState(true);
  const [questionsError, setQuestionsError] = useState(null);
  // Whether or not they're updating their response (taken back from the review screen)
  const [isUpdatingResponse, setIsUpdatingResponse] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [shouldShowHelp, setShouldShowHelp] = useState(false);

  /**
   * Loads the form questions, and starts the survey.
   */
  const loadSurveyQuestions = async () => {
    setQuestionsLoading(true);
    setQuestionsError(null);
    try {
      Analytics.sendEvent('survey_start', {
        category: 'survey',
        action: 'start',
        label: locationName,
      });
      setStartTime(Date.now());
      const questions = getQuestionsForBuilding(locationName);
      if (!questions) {
        return history.replace('/not-found');
      }
      setQuestions(questions);
    } catch (error) {
      setQuestionsError(error);
    }
    setQuestionsLoading(false);
  };

  /**
   * Checks if a user has passed the test. Currently, this means all answers
   * are false or "no".
   */
  const checkIfPassed = () =>
    !questions.map(question => formData[question.id]).some(item => item === true);

  /**
   * Event handler for the form submission. This checks if the user has passed,
   * and updates local storage to reflect it. Then redirects user to the passport
   * page.
   *
   * @param {FormEvent} event the form submission event
   */
  const handleSubmit = event => {
    event.preventDefault();
    const hasPassed = checkIfPassed();
    const submitTime = Date.now();

    const surveyDurationInSeconds = (submitTime - startTime) / 1000;
    Analytics.sendEvent('timing_complete', {
      name: 'survey_duration_sec',
      value: surveyDurationInSeconds,
      label: locationName,
    });
    Analytics.sendEvent('survey_submit', {
      category: 'survey',
      action: 'submit',
      value: hasPassed ? 'pass' : 'fail',
      label: locationName,
    });

    passport.set(hasPassed, submitTime);
  };

  /**
   * Event handler for when a user requests to update their response from the review
   * screen. Updates the current step, and flags that the user is updating a
   * response (so they'll be taken right back to the review screen).
   *
   * @param {nunber} index the question step index to jump to
   */
  const onResponseUpdateRequest = index => {
    setCurrentStep(index);
    setIsUpdatingResponse(true);
  };

  /**
   * Event handler for the back button. This will bring a user back to the
   * previous question, even if they're updating a different one. If they're at the
   * first question, it will default to browser functionality.
   */
  const goBack = () => {
    if (currentStep <= 0) {
      history.replace(`/buildings/${locationName}`);
    } else {
      setIsUpdatingResponse(false);
      setCurrentStep(currentStep - 1);
    }
  };

  /**
   * Closes the help modal, and refocuses the help button trigger.
   */
  const closeHelpModal = () => {
    setShouldShowHelp(false);
    helpButtonRef.current.focus();
  };

  useEffect(() => {
    loadSurveyQuestions();
  }, [locationName]);

  if (passport.hasPassport) {
    return <Redirect to={`/buildings/${locationName}/pass${searchQuery}`} />;
  }

  return (
    <div className={styles.formWrapper}>
      <SEO title="Questionnaire" />
      <Portal
        className={styles.formNav}
        to="header-root"
        prepend
        mounted={transitionState === 'entering' || transitionState === 'entered'}>
        <div className={styles.formNavInner}>
          <div className={styles.formNavItem}>
            <button
              className={styles.formNavButton}
              type="button"
              onClick={goBack}
              aria-label="Go back">
              <span>
                <Arrow />
              </span>
              Back
            </button>
          </div>
          <div className={styles.formNavItem}>
            {!questionsLoading && !questionsError && (
              <Pagination max={questions.length + 1} current={currentStep} />
            )}
          </div>
          <div className={styles.formNavItem}>
            <button
              ref={helpButtonRef}
              type="button"
              aria-controls="help-modal"
              aria-label="Open help modal"
              className={styles.formNavHelpLink}
              onClick={() => setShouldShowHelp(true)}>
              Help
            </button>
            <Help id="help-modal" shouldDisplay={shouldShowHelp} close={closeHelpModal} />
          </div>
        </div>
      </Portal>
      {questionsLoading ? (
        <div className={styles.formLoader}>
          <Loader />
        </div>
      ) : questionsError ? (
        <ErrorNotice error={questionsError} onRetry={loadSurveyQuestions} />
      ) : (
        <Form
          onResponse={(key, value) => updateData(key, value, isUpdatingResponse)}
          onResponseUpdateRequest={onResponseUpdateRequest}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default Questionnaire;
