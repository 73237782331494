import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import * as serviceWorker from '@src/serviceWorker';
import { STORAGE_KEYS } from '@src/utils/constants';
import { ServiceWorkerContext, GlobalHistoryContext } from '@src/utils/contexts';

/**
 * A provider that exposes the service worker process to the React app tree.
 *
 * Learn more about service workers:
 * @see https://bit.ly/CRA-PWA
 */
const ServiceWorkerProvider = ({ children }) => {
  const history = useContext(GlobalHistoryContext);
  const questionnaireRouteMatch = useRouteMatch({ path: '/buildings/:locationName/questionnaire' });

  const registrationRef = useRef(null);
  const [canUpdate, setCanUpdate] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  /**
   * If an update is available, installs the next service worker in place of
   * the current one.
   */
  const updateWorker = () => {
    const registration = registrationRef.current;
    const nextWorker = registration.waiting || registration.installing;
    if (!canUpdate || !nextWorker) {
      return;
    }

    setIsUpdating(true);

    // Temporarily store that a service worker was updated, causing a reload
    localStorage.setItem(STORAGE_KEYS.serviceWorkerUpdated, Date.now());

    // If we can post a message to the worker...
    if (nextWorker.postMessage) {
      // Add listener for worker change, and force refresh when it occurs
      navigator.serviceWorker.addEventListener(
        'controllerchange',
        () => {
          window.location.reload();
        },
        { once: true },
      );
      // Tell the work to skip waiting for reload
      nextWorker.postMessage({
        type: 'SKIP_WAITING',
      });
      return;
    }

    // Otherwise, just manually reload the page
    window.location.reload();
  };

  /**
   * Event handler for when a service worker update is available. Stores the
   * registration state, and toggles flag that we can update.
   *
   * @param {ServiceWorkerRegistration} registration the registration for the next worker
   */
  const onUpdateAvailable = registration => {
    registrationRef.current = registration;
    setCanUpdate(true);
  };

  /**
   * If an update is available, it will occur on next page change
   * or when having the latest data is important.
   */
  useEffect(() => {
    if (canUpdate) {
      // If on questionnaire, force update so we have latest questions
      if (questionnaireRouteMatch) {
        updateWorker();
        return;
      }

      // Otherwise, wait for next page change
      const unlisten = history.listen(() => {
        updateWorker();
      });
      return unlisten;
    }
  }, [canUpdate, questionnaireRouteMatch]);

  /**
   * Registers the service worker.
   */
  useEffect(() => {
    serviceWorker.register({
      immediate: true,
      onUpdate: onUpdateAvailable,
    });
  }, []);

  return (
    <ServiceWorkerContext.Provider value={{ isUpdating }}>{children}</ServiceWorkerContext.Provider>
  );
};

ServiceWorkerProvider.propTypes = {
  children: PropTypes.node,
};

export default ServiceWorkerProvider;
