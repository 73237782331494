import React from 'react';
import styles from '@src/svgs/sharedStyles.module.scss';

const MenuIcon = () => (
  <svg className={styles.root} viewBox="0 0 5 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      className={styles.fill}
      cx="2.50005"
      cy="13.0263"
      r="1.97368"
      transform="rotate(-90 2.50005 13.0263)"
    />
    <circle
      className={styles.fill}
      cx="2.50005"
      cy="7.49995"
      r="1.97368"
      transform="rotate(-90 2.50005 7.49995)"
    />
    <circle
      className={styles.fill}
      cx="2.50005"
      cy="1.97358"
      r="1.97368"
      transform="rotate(-90 2.50005 1.97358)"
    />
  </svg>
);

export default MenuIcon;
