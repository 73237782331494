import { useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import useModalAnimation from '@src/hooks/useModalAnimation';

export default function useSingleModalAnimation(close, shouldDisplay) {
  const modalContentRef = useRef(null);
  const closeButtonRef = useRef(null);
  const modalRef = useRef(null);

  const modalAnimation = useModalAnimation({
    getModal: () => modalRef.current,
    getModalContent: () => modalContentRef.current,
    onExited: close,
  });

  const onClose = () => {
    modalAnimation.playExit();
  };

  const escapeListener = event => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      onClose();
    }
  };

  useEffect(() => {
    if (shouldDisplay) {
      modalAnimation.playEnter();
      process.nextTick(() => {
        closeButtonRef.current.focus();
      });
    }
  }, [shouldDisplay]);

  useEffect(() => {
    if (shouldDisplay) {
      document.addEventListener('keydown', escapeListener);
    }

    return () => {
      document.removeEventListener('keydown', escapeListener);
    };
  }, [escapeListener]);

  // Locks body from scrolling when help modal is visible
  useEffect(() => {
    if (shouldDisplay) {
      disableBodyScroll(modalContentRef.current);
    }

    return () => {
      enableBodyScroll(modalContentRef.current);
    };
  }, [shouldDisplay]);

  return {
    modalContentRef,
    closeButtonRef,
    modalRef,
    onClose,
  };
}
