import React, { useContext, Fragment } from 'react';
import PropTypes from 'prop-types';
import Question from '@src/components/Question';
import Review from '@src/components/Review';
import Transition from '@src/components/Transition';
import useAnnounce from '@src/hooks/useAnnounce';
import useSlidingTransition from '@src/hooks/useSlidingTransition';
import { className } from '@src/utils';
import { FormDataContext } from '@src/utils/contexts';
import styles from './form.module.scss';
import { TransitionGroup } from 'react-transition-group';

const Form = ({ onResponse, onResponseUpdateRequest, onSubmit }) => {
  const { questions, hasFinished, currentStep, formData, stepsRef } = useContext(FormDataContext);

  const ScreenTransitions = useSlidingTransition({
    getTranslateX(isEntering) {
      const { lastStep, currentStep } = stepsRef.current;
      if (isEntering) {
        return lastStep > currentStep ? -10 : 10;
      }
      return lastStep > currentStep ? 10 : -10;
    },
  });

  const showReviewScreen = hasFinished && currentStep === questions.length;

  useAnnounce(() => {
    if (showReviewScreen) {
      return 'Review';
    }
    return `Question ${currentStep + 1}`;
  }, [currentStep, showReviewScreen]);

  return (
    <form
      {...className(styles.form, ScreenTransitions.isTransitioning && styles.formTransition)}
      onSubmit={onSubmit}>
      <TransitionGroup component={null}>
        <Transition
          key={currentStep}
          appear
          unmountOnExit
          timeout={ScreenTransitions.timeout}
          onEnter={ScreenTransitions.onEnter}
          onExit={ScreenTransitions.onExit}>
          <div className={styles.formScreen}>
            {!showReviewScreen ? (
              <Question
                questionObject={questions[currentStep]}
                onResponse={value => onResponse(questions[currentStep].id, value)}
              />
            ) : (
              <Fragment>
                <Review
                  onChange={onResponseUpdateRequest}
                  questions={questions}
                  responses={formData}
                />
                <div className={styles.formSubmit}>
                  <button className={styles.formSubmitButton} type="submit">
                    Submit
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </Transition>
      </TransitionGroup>
    </form>
  );
};

Form.propTypes = {
  onResponse: PropTypes.func.isRequired,
  onResponseUpdateRequest: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default Form;
