import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Circ, Expo, Linear, TimelineLite } from 'gsap';
import { Logo } from '@src/svgs';
import { DEFAULT_SPLASH_ID } from '@src/utils/constants';
import { SplashScreenContext } from '@src/utils/contexts';
import styles from './splash.module.scss';

// The average milliseconds per word, based on an average score of 238 WPM
// One word is considered to be about 5 characters
// @see https://digest.bps.org.uk/2019/06/13/most-comprehensive-review-to-date-suggests-the-average-persons-reading-speed-is-slower-than-commonly-thought/
const MS_PER_WORD = (1000 * 60) / 238;

const STATUS = {
  mounted: 'mounted',
  entered: 'entered',
  exited: 'exited',
  unmounted: 'unmounted',
};

const Splash = ({ onComplete }) => {
  const splashRef = useRef(null);
  const placeholderRef = useRef(null);
  const contentRef = useRef(null);

  const [status, setStatus] = useState(STATUS.unmounted);

  const [{ dimensions, isVisible, text }, setSplashScreenContext] = useContext(SplashScreenContext);

  const SplashAnimations = {
    playEnter() {
      const timeline = new TimelineLite();
      timeline.set(splashRef.current, {
        opacity: 1,
        visibility: 'visible',
      });
      timeline.fromTo(
        placeholderRef.current,
        {
          height: '100vh',
          opacity: 0,
          skewY: -30,
          width: '80vw',
          x: '-30vw',
          y: '22.5vh',
        },
        {
          delay: 0.3,
          duration: 0.3,
          ease: Circ.easeOut,
          opacity: 1,
          y: '20vh',
        },
        0,
      );
      timeline.fromTo(
        contentRef.current,
        {
          opacity: 0,
          y: 10,
        },
        {
          duration: 0.3,
          ease: Circ.easeOut,
          opacity: 1,
          y: 0,
        },
        '-=0.2',
      );
    },
    playExit() {
      const timeline = new TimelineLite();
      if (dimensions) {
        timeline.to(placeholderRef.current, {
          duration: 0.3,
          ease: Expo.easeOut,
          height: dimensions.height,
          skewY: 0,
          width: dimensions.width,
          x: dimensions.x,
          y: dimensions.y,
        });
      } else {
        timeline.to(placeholderRef.current, {
          duration: 0.15,
          ease: Linear.easeOut,
          opacity: 0,
        });
      }
      timeline.to(
        contentRef.current,
        {
          duration: 0.15,
          ease: Linear.easeOut,
          opacity: 0,
        },
        0,
      );
      timeline.then(onComplete);
    },
    playUnmount() {
      const timeline = new TimelineLite();
      timeline.to(splashRef.current, {
        duration: 0.3,
        opacity: 0,
        ease: Linear.easeOut,
      });
      timeline.set(splashRef.current, {
        visibility: 'hidden',
      });
    },
  };

  // Controls the timeout between the enter and exit animations
  const exitTimeoutMs = useRef(1000);

  // Controls the status of the splash screen and animation state
  useEffect(() => {
    if (isVisible) {
      if (status === STATUS.unmounted) {
        setStatus(STATUS.mounted);
        disableBodyScroll(splashRef.current);
      } else if (status === STATUS.mounted) {
        SplashAnimations.playEnter();
        setStatus(STATUS.entered);
        // Set exit timeout based on text length on screen
        // Base is set to 150ms to account for enter animation delay
        exitTimeoutMs.current =
          150 + Math.max((text.replace(/\s/g, '').length / 5) * MS_PER_WORD, 850);
      } else if (status === STATUS.entered) {
        const startTime = Date.now();
        const exitTimeout = setTimeout(() => {
          SplashAnimations.playExit();
          setStatus(STATUS.exited);
        }, exitTimeoutMs.current);

        return () => {
          clearTimeout(exitTimeout);
          // Account for previously passed time to prevent double timeout
          const timePassed = Date.now() - startTime;
          exitTimeoutMs.current = Math.max(0, exitTimeoutMs.current - timePassed);
        };
      }
    } else if (status === STATUS.exited) {
      SplashAnimations.playUnmount();
      setSplashScreenContext(context => {
        if (!context.hasRunFor.length) {
          return {
            ...context,
            hasRunFor: [DEFAULT_SPLASH_ID],
          };
        }
        return context;
      });
      setStatus(STATUS.unmounted);
      enableBodyScroll(splashRef.current);
    }
  }, [dimensions, isVisible, status, text]);

  return (
    <div ref={splashRef} className={styles.splash} aria-hidden={true}>
      <div ref={contentRef} className={styles.splashContent}>
        <h1 className={styles.splashContentTitle}>{text}</h1>
        <div className={styles.splashContentLogo}>
          <Logo />
        </div>
      </div>
      <div className={styles.splashPlaceholder}>
        <div ref={placeholderRef} className={styles.splashPlaceholderContent} />
      </div>
    </div>
  );
};

Splash.propTypes = {
  onComplete: PropTypes.func,
};

export default Splash;
