import React from 'react';
import PropTypes from 'prop-types';
import { useState } from '@upstatement/react-hooks';
import { useHistory } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { GlobalHistoryContext, ModalNavHistoryContext } from '@src/utils/contexts';

const HistoryProvider = ({ children }) => {
  const globalHistory = useHistory();
  const [modalNavHistory] = useState(createMemoryHistory({ initialEntries: ['/menu'] }));

  return (
    <GlobalHistoryContext.Provider value={globalHistory}>
      <ModalNavHistoryContext.Provider value={modalNavHistory}>
        {children}
      </ModalNavHistoryContext.Provider>
    </GlobalHistoryContext.Provider>
  );
};

HistoryProvider.propTypes = {
  children: PropTypes.node,
};

export default HistoryProvider;
