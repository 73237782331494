import React from 'react';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import Portal from '@src/components/Portal';
import useSingleModalAnimation from '../useSingleModalAnimation';
import { Arrow } from '@src/svgs';
import styles from './temp.module.scss';

const CDC_LINK = 'https://www.cdc.gov/coronavirus/2019-ncov/community/general-business-faq.html';

const TempFAQ = ({ close, shouldDisplay, id }) => {
  const { modalContentRef, closeButtonRef, modalRef, onClose } = useSingleModalAnimation(
    close,
    shouldDisplay,
  );

  return (
    <Portal to="modal-root" mounted={shouldDisplay}>
      <FocusLock disabled={!shouldDisplay} noFocusGuards>
        <div className={styles.container}>
          <div
            id={id}
            className={styles.tempModal}
            role="dialog"
            aria-label="Help Modal"
            aria-modal="true">
            <div ref={modalRef} className={styles.tempModalInner}>
              <nav>
                <button ref={closeButtonRef} className={styles.tempModalClose} onClick={onClose}>
                  <i className={styles.tempModalCloseIcon}>
                    <Arrow />
                  </i>
                  <span className={styles.tempModalCloseText}>Dismiss</span>
                </button>
              </nav>
              <section ref={modalContentRef} className={styles.tempModalContent}>
                <header className={styles.tempModalHeader}>
                  <h1 id="temp-modal-label" className={styles.tempModalHeaderTitle}>
                    Temperature Check
                  </h1>
                  <h2 className={styles.tempModalHeaderDescription}>Frequent Questions</h2>
                </header>
                <article className={styles.freeText}>
                  <h3>Why Does the Building Conduct a Temperature Scan?</h3>
                  <p>
                    Beacon Capital is committed to the safety of everyone who enters the building.
                    <a href={CDC_LINK}>According to the CDC</a>, people who have a fever a more
                    likely to be sick and could be at higher risk of spreading illness.
                  </p>
                </article>
                <article className={styles.freeText}>
                  <h3>What's a Normal Temperature?</h3>
                  <p>
                    <a href={CDC_LINK}>CDC guidelines</a> advise that anyone with a temperature of
                    less than 100.4° F / 38.0° C is considered within a normal range.
                  </p>
                </article>
                <article className={styles.freeText}>
                  <h3>What Happens If Someone Has a Fever?</h3>
                  <p>
                    People who register a fever are not allowed to enter the building and are
                    encouraged to contact a medical professional.
                  </p>
                </article>
                <article className={styles.freeText}>
                  <h3>Is Any Information Saved?</h3>
                  <p>No. The scans are anonymous and no information is saved.</p>
                </article>
              </section>
            </div>
          </div>
        </div>
      </FocusLock>
    </Portal>
  );
};

TempFAQ.propTypes = {
  close: PropTypes.func,
  shouldDisplay: PropTypes.bool,
  id: PropTypes.string,
};

export default TempFAQ;
