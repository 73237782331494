import React from 'react';
import SEO from '@src/components/SEO';
import useAnnounce from '@src/hooks/useAnnounce';
import { AddToHomeIcon, HomescreenIcon, MenuIcon } from '@src/svgs';
import styles from './addToHome.module.scss';

const AddToHome = () => {
  useAnnounce(() => 'Add to Home Screen Instructions');

  return (
    <section className={styles.page}>
      <SEO title="Add To Home" />
      <div className={styles.pageInner}>
        <header>
          <h1 className={styles.title}>How to add Beacon Check-In to your home screen.</h1>
        </header>
        <div className={styles.freeText}>
          <p>Installing the Beacon app gives you easy access to your daily passport. Here’s how.</p>
          <h2>iPhone</h2>
          <ol className={styles.iconList}>
            <li>
              <span aria-hidden="true" className={styles.shareIcon}>
                <HomescreenIcon />
              </span>
              <strong>Tap the share icon </strong>
              Typically found in the menu bar at the bottom of your screen.
            </li>
            <li>
              <span aria-hidden="true" className={styles.addToHomeIcon}>
                <AddToHomeIcon />
              </span>
              <strong>Choose ‘Add to Home Screen’</strong>
              From the menu that appears.
            </li>
          </ol>
          <p>Please make sure you’re using Safari. This doesn’t work in Google Chrome.</p>
          <h2>Android</h2>
          <ol className={styles.iconList}>
            <li>
              <span aria-hidden="true" className={styles.menuIcon}>
                <MenuIcon />
              </span>
              <strong>Tap the menu icon </strong>
              Typically shown as three dots in the upper right-hand corner.
            </li>
            <li>
              <span aria-hidden="true" className={styles.addToHomeIcon}>
                <AddToHomeIcon />
              </span>
              <strong>Choose ‘Add to Home Screen’</strong>
              From the menu that appears.
            </li>
          </ol>
        </div>
      </div>
    </section>
  );
};

export default AddToHome;
