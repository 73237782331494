import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Layout from '@src/components/Layout';
import Routes from '@src/components/Routes';
import ProviderGroup from '@src/providers';

const App = () => (
  <BrowserRouter>
    <ProviderGroup>
      <Layout>{location => <Routes location={location} />}</Layout>
    </ProviderGroup>
  </BrowserRouter>
);

export default App;
