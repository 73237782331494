import React, { Children, useRef } from 'react';
import PropTypes from 'prop-types';
import { Transition as NativeTransition } from 'react-transition-group';
import { TransitionContext } from '@src/utils/contexts';
import { useState } from '@upstatement/react-hooks';

/**
 * A light wrapper around `react-transition-group`'s `Transition` component. With
 * StrictMode enabled, RTG's use of the legacy `findDOMNode` causes errors. However,
 * they do include an opt-in way to use the latest context API to handle transition.
 *
 * This wrapper opts in to the new API by assigning and tracking a ref of its child.
 * This is then passed to the `nodeRef` prop, and any transition event handlers.
 *
 * @see https://github.com/reactjs/react-transition-group/issues/429
 * @see https://github.com/reactjs/react-transition-group/blob/1fd4a65ac45edd2aea3dec18eeb8b9c07c7eb93f/CHANGELOG.md#features
 */
const Transition = ({
  appear,
  children,
  in: inFlag,
  onEnter: onCustomEnter,
  onExit: onCustomExit,
  timeout,
  unmountOnExit,
}) => {
  const childRef = useRef(null);

  const [isAppearing, setIsAppearing] = useState(false);

  const onEnter = isAppearing => {
    setIsAppearing(isAppearing);
    if (onCustomEnter) {
      onCustomEnter(childRef.current, isAppearing);
    }
  };

  const onExit = () => {
    setIsAppearing(false);
    if (onCustomExit) {
      onCustomExit(childRef.current);
    }
  };

  const child = Children.only(children);
  const childWithRef = React.cloneElement(child, {
    ref: childRef,
  });

  return (
    <NativeTransition
      nodeRef={childRef}
      in={inFlag}
      appear={appear}
      timeout={timeout}
      unmountOnExit={unmountOnExit}
      onEnter={onEnter}
      onExit={onExit}>
      {state => (
        <TransitionContext.Provider value={[state, isAppearing]}>
          {childWithRef}
        </TransitionContext.Provider>
      )}
    </NativeTransition>
  );
};

Transition.propTypes = {
  appear: PropTypes.bool,
  children: PropTypes.node.isRequired,
  in: PropTypes.bool,
  onEnter: PropTypes.func,
  onExit: PropTypes.func,
  timeout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]).isRequired,
  unmountOnExit: PropTypes.bool,
};

Transition.defaultProps = {
  appear: false,
  in: false,
  unmountOnExit: false,
};

export default Transition;
