import { useRef } from 'react';
import { Expo, TimelineLite } from 'gsap/gsap-core';

/**
 * Custom React hook for adding a modal-like popup animaion.
 *
 * @param {object} options the animation options
 * @param {boolean} [options.isAppearing] whether the modal has already mounted (appeared). A falsy value
 * will prevent the enter animation from playing
 * @param {() => HTMLElement} options.getModal retrieves the modal container element
 * @param {() => HTMLElement} options.getModalContent retrieves the modal content container element
 * @param {() => void} [options.onEntered] callback for after the modal has entered
 * @param {() => void} [options.onExited] callback for after the modal has exited
 */
const useModalAnimation = ({ isAppearing, getModal, getModalContent, onEntered, onExited }) => {
  const timelineRef = useRef(null);

  const getTimeline = () => {
    if (timelineRef.current) {
      timelineRef.current.kill();
    }
    timelineRef.current = new TimelineLite();
    return timelineRef.current;
  };

  return {
    playEnter() {
      const modal = getModal();
      const modalContent = getModalContent();

      const timeline = getTimeline();

      if (!isAppearing) {
        timeline.set(modal, {
          translateY: '100%',
          left: 0,
          maxWidth: 'initial',
          maxHeight: 'initial',
          opacity: 0,
          visibility: 'visible',
        });
        timeline.to(modal, 0.4, {
          ease: Expo.easeOut,
          translateY: '0%',
        });
        timeline.to(
          modal,
          0.15,
          {
            opacity: 1,
          },
          0,
        );
      }

      timeline.fromTo(
        modalContent,
        0.4,
        {
          opacity: 0,
        },
        {
          delay: isAppearing ? 0.2 : 0,
          opacity: 1,
        },
        isAppearing ? 0 : `-=0.15`,
      );

      if (onEntered) {
        timeline.then(onEntered);
      }
    },
    playExit() {
      const modal = getModal();

      const timeline = getTimeline();
      timeline.fromTo(
        modal,
        0.4,
        {
          translateY: '0%',
        },
        {
          ease: Expo.easeOut,
          translateY: '100%',
        },
      );
      timeline.fromTo(
        modal,
        0.2,
        {
          opacity: 1,
        },
        {
          opacity: 0,
        },
        `-=0.2`,
      );
      timeline.set(modal, {
        left: -5000,
        maxWidth: 0,
        maxHeight: 0,
        visibility: 'hidden',
      });

      if (onExited) {
        timeline.then(onExited);
      }
    },
  };
};

export default useModalAnimation;
