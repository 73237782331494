import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useState } from '@upstatement/react-hooks';
import { DEFAULT_SPLASH_ID, STORAGE_KEYS } from '@src/utils/constants';
import { SplashScreenContext, OnboardingContext } from '@src/utils/contexts';

const SplashScreenProvider = ({ children }) => {
  const { hasOnboarded } = useContext(OnboardingContext);

  const splashState = useState(() => {
    const initialState = {
      dimensions: null,
      hasRunFor: [],
      isVisible: true,
      text: `Let's keep our workplace safe.`,
    };
    if (!hasOnboarded || localStorage.getItem(STORAGE_KEYS.serviceWorkerUpdated)) {
      localStorage.removeItem(STORAGE_KEYS.serviceWorkerUpdated);
      initialState.isVisible = false; // Don't show the splash screen initially
      initialState.hasRunFor.push(DEFAULT_SPLASH_ID); // Prevent other pages from toggling the splash screen
    }
    return initialState;
  });

  return (
    <SplashScreenContext.Provider value={splashState}>{children}</SplashScreenContext.Provider>
  );
};

SplashScreenProvider.propTypes = {
  children: PropTypes.node,
};

export default SplashScreenProvider;
