import React, { useContext } from 'react';
import Link from '@src/components/Link';
import useAnnounce from '@src/hooks/useAnnounce';
import { STORAGE_KEYS } from '@src/utils/constants';
import { PassportContext } from '@src/utils/contexts';
import { eligibleToAddToHomescreen } from '@src/utils/pwa-prompt';
import styles from './menu.module.scss';

const Menu = () => {
  const HOT_JAR_URL = 'https://surveys.hotjar.com/ffc838ab-a060-4ea0-b1fd-6fcfcf1aa08d';
  const passport = useContext(PassportContext);

  useAnnounce(() => 'Menu');

  const selectedBuilding = localStorage.getItem(STORAGE_KEYS.selectedBuilding);

  // Define primary links
  const primaryLinks = [
    {
      to: '/buildings',
      text: `${selectedBuilding ? 'Change' : 'Choose'} Location`,
    },
    {
      to: '/how-it-works',
      text: 'How it Works',
    },
    {
      to: '/about',
      text: 'About',
    },
  ];

  if (selectedBuilding) {
    if (passport.hasPassport) {
      primaryLinks.unshift({
        to: `/buildings/${selectedBuilding}/pass`,
        text: 'Your Pass',
      });
    } else {
      primaryLinks.unshift({
        to: `/buildings/${selectedBuilding}/questionnaire`,
        text: 'Check-In',
      });
    }
  }

  // Define secondary links
  const secondaryLinks = [
    {
      to: '/privacy-policy',
      text: 'Privacy Policy',
    },
    {
      to: '/support',
      text: 'Need Help?',
    },
  ];

  if (eligibleToAddToHomescreen) {
    secondaryLinks.unshift({
      to: '/add-to-home',
      text: 'Add To Home Screen',
    });
  }

  return (
    <section className={styles.menu}>
      <div className={styles.menuInner}>
        <h1 className={styles.menuHeader}>Menu</h1>

        <ul className={styles.menuList} role="group">
          {primaryLinks.map(({ to, text }) => (
            <li key={to} className={styles.menuItem}>
              <Link className={styles.menuLink} to={{ pathname: to, state: { title: text } }}>
                {text}
              </Link>
            </li>
          ))}
        </ul>

        <ul className={styles.secondaryList} role="group">
          {secondaryLinks.map(({ to, text }) => (
            <li key={to} className={styles.secondaryItem}>
              <Link className={styles.secondaryLink} to={{ pathname: to, state: { title: text } }}>
                {text}
              </Link>
            </li>
          ))}
          <li className={styles.secondaryItem}>
            <a
              className={styles.secondaryLink}
              href={HOT_JAR_URL}
              rel="noopener noreferrer"
              target="_blank">
              Send Feedback
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Menu;
