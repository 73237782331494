import React, { Fragment, useContext, useRef, useEffect, useState } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import FocusLock from 'react-focus-lock';
import { Route, Router } from 'react-router-dom';
import Link from '@src/components/Link';
import Portal from '@src/components/Portal';
import ModalNavContent from './ModalNavContent';
import useModalAnimation from '@src/hooks/useModalAnimation';
import { Arrow } from '@src/svgs';
import { className } from '@src/utils';
import {
  GlobalHistoryContext,
  ModalNavContext,
  ModalNavHistoryContext,
  SpotColorsContext,
} from '@src/utils/contexts';
import styles from './modalNav.module.scss';

const ModalNav = () => {
  const globalHistory = useContext(GlobalHistoryContext);
  const modalNavHistory = useContext(ModalNavHistoryContext);

  const { spotColors } = useContext(SpotColorsContext);
  const { isOpen, setIsOpen, getPaths: getModalPaths } = useContext(ModalNavContext);

  const [isModalRoute, setIsModalRoute] = useState(false);
  const [returnRoute, setReturnRoute] = useState();

  const modalNavRef = useRef(null);
  const modalNavContentRef = useRef(null);
  const openBtnRef = useRef(null);
  const closeBtnRef = useRef(null);

  const ModalNavAnimations = useModalAnimation({
    getModal: () => modalNavRef.current,
    getModalContent: () => modalNavContentRef.current,
  });

  const closeOnEscape = evt => {
    switch (evt.key) {
      case 'Esc':
      case 'Escape': {
        setIsOpen(false);
        break;
      }
    }
  };

  const onOpen = () => {
    // Play enter animation and focus close button
    ModalNavAnimations.playEnter();
    process.nextTick(() => {
      closeBtnRef.current.focus();
    });
    // Disable body scroll and show light background
    disableBodyScroll(modalNavContentRef.current);
    document.body.classList.add('body--light');
    // Listen to escape key
    window.addEventListener('keydown', closeOnEscape);
    // Close the modal nav when a "global" app link is navigated to
    const unlistenToGlobalHistory = globalHistory.listen(() => {
      setIsOpen(false);
    });

    const unlistenToModalHistory = modalNavHistory.listen(({ pathname }) => {
      if (pathname !== '/menu') {
        window.history.pushState(null, '', pathname);
      }
    });

    return () => {
      window.removeEventListener('keydown', closeOnEscape);
      unlistenToGlobalHistory();
      unlistenToModalHistory();
    };
  };

  const onClose = () => {
    // Play exit animation and focus open button
    ModalNavAnimations.playExit();
    openBtnRef.current.focus();
    // Re-enable body scroll and default to main bg color
    enableBodyScroll(modalNavContentRef.current);
    document.body.classList.remove('body--light');
    // Open the modal nav when an in-modal link is navigated to
    const unlistenToModalNavHistory = modalNavHistory.listen(() => {
      setIsOpen(true);
    });

    // When the global history changes with the menu modal closed, compare the new
    // pathname with the modal paths. If the global path is one of the modal paths,
    // open the modal and show the relevant content.
    const unlistenToGlobalHistory = globalHistory.listen(({ pathname }) => {
      if (getModalPaths().includes(pathname)) {
        modalNavHistory.push(pathname);
        setIsModalRoute(true);
        setIsOpen(true);
      }
    });

    return () => {
      unlistenToModalNavHistory();
      unlistenToGlobalHistory();
    };
  };

  useEffect(() => {
    if (isOpen) {
      setReturnRoute(globalHistory.location.pathname);
      return onOpen();
    } else {
      if (isModalRoute) {
        setIsModalRoute(false);
        globalHistory.push('/');
      } else if (returnRoute) {
        window.history.pushState(null, '', returnRoute);
      }

      return onClose();
    }
  }, [isOpen]);

  useEffect(() => {
    if (getModalPaths().includes(globalHistory.location.pathname)) {
      modalNavHistory.push(globalHistory.location.pathname);
      setIsModalRoute(true);
      setIsOpen(true);
    }
  }, []);

  return (
    <Fragment>
      <button
        ref={openBtnRef}
        {...className(styles.modalOpen, spotColors.main && styles.modalOpenWithSpot)}
        type="button"
        aria-label="Open Menu"
        aria-controls="modal-nav"
        aria-expanded={isOpen}
        onClick={() => {
          modalNavHistory.replace('/menu');
          setIsOpen(true);
        }}>
        Menu
      </button>
      <Router history={modalNavHistory}>
        <Portal to="modal-root" mounted>
          <FocusLock disabled={!isOpen} noFocusGuards>
            {isOpen && (
              <button
                className={styles.modalNavBackdrop}
                aria-hidden="true"
                tabIndex={-1}
                onClick={() => setIsOpen(false)}></button>
            )}
            <div
              ref={modalNavRef}
              id="modal-nav"
              className={styles.modalNav}
              role="dialog"
              aria-label="Menu"
              aria-modal="true"
              aria-hidden={!isOpen}>
              <header className={styles.modalNavHeader}>
                <div className={styles.modalNavHeaderInner}>
                  <div>
                    <Route>
                      {({ location }) =>
                        location.pathname !== '/menu' && (
                          <Link
                            className={styles.modalNavMenuLink}
                            to={{ pathname: '/menu', state: { title: 'Menu' } }}
                            aria-label="Back to Menu">
                            <i>
                              <Arrow />
                            </i>
                            Menu
                          </Link>
                        )
                      }
                    </Route>
                  </div>
                  <button
                    ref={closeBtnRef}
                    className={styles.modalNavClose}
                    onClick={() => setIsOpen(false)}>
                    <i className={styles.modalNavCloseIcon}>
                      <Arrow />
                    </i>
                    <span className={styles.modalNavCloseText}>Dismiss</span>
                  </button>
                </div>
              </header>
              <ModalNavContent ref={modalNavContentRef} />
            </div>
          </FocusLock>
        </Portal>
      </Router>
    </Fragment>
  );
};

export default ModalNav;
