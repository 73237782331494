import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './nativeInstallBanner.module.scss';

const NativeInstallBanner = ({ visibility = false, setVisibility = () => {} }) => {
  const handleBeforeInstallPrompt = e => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();

    setVisibility(true);
  };

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  if (!visibility) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <a
        href="https://play.google.com/store/apps/details?id=com.beaconcp.screener"
        className={styles.bannerLink}>
        <div className={styles.bannerLinkImg}>
          <img src="/android-chrome-192x192.png" alt="Beacon Passport native app" />
        </div>
        <p className={styles.bannerLinkText}>
          Get the Beacon Passport app on&nbsp;Google&nbsp;Play
        </p>
      </a>
      <button className={styles.close} onClick={() => setVisibility(false)}>
        &times;
      </button>
    </div>
  );
};

NativeInstallBanner.propTypes = {
  visibility: PropTypes.bool,
  setVisibility: PropTypes.func,
};

NativeInstallBanner.defaultProps = {
  visibility: false,
  setVisibility: () => {},
};

export default NativeInstallBanner;
