import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useState } from '@upstatement/react-hooks';
import { KioskModeContext } from '@src/utils/contexts';
import { STORAGE_KEYS } from '@src/utils/constants';

const KioskModeProvider = ({ children }) => {
  const { search } = useLocation();

  const [isEnabled] = useState(() => {
    if (typeof window.URLSearchParams !== 'undefined') {
      return new URLSearchParams(search).has('kiosk');
    }
    return false;
  });

  const restart = () => {
    if (!isEnabled) {
      return;
    }

    localStorage.removeItem(STORAGE_KEYS.success);
    localStorage.removeItem(STORAGE_KEYS.timestamp);
    window.location.reload();
  };

  const searchQuery = isEnabled ? '?kiosk' : '';

  return (
    <KioskModeContext.Provider value={{ isEnabled, searchQuery, restart }}>
      {children}
    </KioskModeContext.Provider>
  );
};

KioskModeProvider.propTypes = {
  children: PropTypes.node,
};

export default KioskModeProvider;
