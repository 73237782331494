import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import Failure from './Failure';
import Success from './Success';
import TempCheck from './TempCheck';
import Loader from '@src/components/Loader';
import useAnnounce from '@src/hooks/useAnnounce';
import useSplash from '@src/hooks/useSplash';
import { className } from '@src/utils';
import { KioskModeContext, PassportContext } from '@src/utils/contexts';
import getBuildingByName from '@src/utils/get-building-by-name';
import styles from './passport.module.scss';

const Passport = () => {
  const { success, timestamp, hasPassport } = useContext(PassportContext);

  const { locationName } = useParams();
  const [building, setBuilding] = useState(null);

  const kioskMode = useContext(KioskModeContext);

  // Should the transition run for either the success or failure passport?
  const withSplashTransition = useSplash(success ? Success.SPLASH_ID : Failure.SPLASH_ID);

  useAnnounce(() => {
    if (hasPassport) {
      return 'Passport';
    }
  }, [hasPassport]);

  useEffect(() => {
    if (locationName) {
      const building = getBuildingByName(locationName);
      if (!building) {
        return history.push('/buildings');
      }
      setBuilding(building);
    }
  }, [locationName]);

  if (!building) {
    return (
      <div className={styles.loadingIndicator}>
        <Loader />
      </div>
    );
  }

  if (!hasPassport) {
    return <Redirect to={`/${kioskMode.searchQuery}`} />;
  }

  if (building.fields['Temp Scan?'] && !kioskMode.isEnabled) {
    return <TempCheck />;
  } else {
    return (
      <div {...className(styles.page, withSplashTransition && styles.pageWithDelay)}>
        {success ? <Success timestamp={timestamp} building={building} /> : <Failure />}
      </div>
    );
  }
};

export default Passport;
