import React, { useContext, useRef, useEffect, useState } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import Image from '@src/components/Image';
import Link from '@src/components/Link';
import Loader from '@src/components/Loader';
import SEO from '@src/components/SEO';
import useAnnounce from '@src/hooks/useAnnounce';
import useSplash from '@src/hooks/useSplash';
import getBuildingByName from '@src/utils/get-building-by-name';
import { STORAGE_KEYS } from '@src/utils/constants';
import {
  FormDataContext,
  FullHeightContext,
  KioskModeContext,
  PassportContext,
  SplashScreenContext,
  TransitionContext,
} from '@src/utils/contexts';
import styles from './buildingHome.module.scss';

const BuildingHome = () => {
  const history = useHistory();
  const kioskMode = useContext(KioskModeContext);

  const { hasPassport } = useContext(PassportContext);

  const { locationName } = useParams();
  const selectedBuilding = localStorage.getItem(STORAGE_KEYS.selectedBuilding);
  const [building, setBuilding] = useState(null);

  const imageRef = useRef(null);

  const { hasStartedSurvey } = useContext(FormDataContext);
  const fullHeightContext = useContext(FullHeightContext);
  const [, setSplashContext] = useContext(SplashScreenContext);
  const [transitionState] = useContext(TransitionContext);

  const withSplash = useSplash(BuildingHome.SPLASH_ID);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.selectedBuilding, locationName);
    const building = getBuildingByName(locationName);
    if (!building) {
      return history.push(`/buildings${kioskMode.searchQuery}`);
    }
    setBuilding(building.fields);
  }, [locationName]);

  useEffect(() => {
    if (transitionState === 'entered' && building && imageRef.current) {
      const dimensions = imageRef.current.getBoundingClientRect();
      setSplashContext(oldContext => ({
        ...oldContext,
        dimensions,
      }));
    }
  }, [transitionState, building]);

  useEffect(() => {
    if (
      withSplash &&
      (!fullHeightContext.willUpdate || fullHeightContext.value) &&
      building &&
      imageRef.current
    ) {
      const dimensions = imageRef.current.getBoundingClientRect();
      setSplashContext(context => ({
        ...context,
        dimensions,
        hasRunFor: [...context.hasRunFor, BuildingHome.SPLASH_ID],
      }));
    }
  }, [fullHeightContext, withSplash, building]);

  const shouldRedirect = hasPassport && selectedBuilding;

  useAnnounce(() => {
    if (!shouldRedirect) {
      return selectedBuilding;
    }
  }, [shouldRedirect]);

  if (shouldRedirect) {
    return <Redirect to={`/buildings/${selectedBuilding}/pass${kioskMode.searchQuery}`} />;
  } else if (!building) {
    return (
      <div className={styles.loadingIndicator}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={styles.home}>
      <SEO title={building.Name} />
      <div className={styles.homeContent}>
        <h1 className={styles.homeContentTitle}>
          Welcome to <span>{building.Name}</span>
        </h1>

        <p className={styles.homeContentDescription}>
          For everyone’s safety, please answer a few quick questions before you enter
        </p>
        <div className={styles.portrait}>
          <figure className={styles.portraitContainer}>
            <Image
              ref={imageRef}
              className={styles.portraitContainerImage}
              src={building.Image[0].url}
              alt={`${building.Name} office building`}
            />
          </figure>
        </div>
      </div>
      <footer className={styles.homeFooter}>
        <Link className={styles.surveyLink} to={`/buildings/${locationName}/questionnaire`}>
          {hasStartedSurvey ? 'Resume' : 'Start'}
          <span>Check-in</span>
        </Link>
      </footer>
    </div>
  );
};

BuildingHome.SPLASH_ID = 'buildingHome';

export default BuildingHome;
