// Import polyfills
import 'react-app-polyfill/ie11';
import '@src/utils/initPolyfills';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import gsap, { CSSPlugin } from 'gsap';
import App from '@src/App';
import '@src/style/index.scss';

const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    environment: process.env.REACT_APP_SENTRY_DEPLOY_ENV,
    release: process.env.REACT_APP_SENTRY_RELEASE,
  });
}

gsap.registerPlugin(CSSPlugin);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
