import React from 'react';
import PropTypes from 'prop-types';
import { useState } from '@upstatement/react-hooks';
import { SpotColorsContext } from '@src/utils/contexts';
import styles from './spotColorsProvider.module.scss';

const SpotColorsProvider = ({ children }) => {
  const [spotColors, setSpotColors] = useState({ main: null, secondary: null, isLight: false });

  const updateSpotColors = nextSpotColors => {
    setSpotColors(nextSpotColors);
    // Set the spot color CSS variables
    if (nextSpotColors.main) {
      document.documentElement.style.setProperty('--spot-color', nextSpotColors.main);
    }
    if (nextSpotColors.secondary) {
      document.documentElement.style.setProperty(
        '--spot-secondary-color',
        nextSpotColors.secondary,
      );
    }
    if (nextSpotColors.isLight) {
      document.documentElement.style.setProperty('--spot-text-color', styles.varTextColorOnLight);
    }
    // Add body class to transition to spot color
    document.body.classList.add('body--spot');
  };

  const resetSpotColors = () => {
    setSpotColors({ main: null, secondary: null, isLight: false });
    // Remove body class
    document.body.classList.remove('body--spot');
    // Reset global CSS variables
    document.documentElement.style.removeProperty('--spot-color');
    document.documentElement.style.removeProperty('--spot-secondary-color');
    document.documentElement.style.removeProperty('--spot-text-color');
  };

  return (
    <SpotColorsContext.Provider
      value={{
        spotColors,
        setSpotColors: updateSpotColors,
        resetSpotColors,
      }}>
      {children}
    </SpotColorsContext.Provider>
  );
};

SpotColorsProvider.propTypes = {
  children: PropTypes.node,
};

export default SpotColorsProvider;
