/**
 * Adds polyfill for `Array.find`, which is incompatible with IE11.
 */
if (!Array.prototype.find) {
  Array.prototype.find = function arrayFindPolyfill(callback) {
    return this.filter(callback)[0];
  };
}

/**
 * Adds polyfill for browsers that strip localStorage in private browsing mode
 */
if (!window.localStorage) {
  window.localStorage = {
    getItem() {},
    setItem() {},
    removeItem() {},
  };
}
