import React from 'react';
import PropTypes from 'prop-types';
import styles from './review.module.scss';

const Review = ({ onChange, questions, responses }) => {
  const onResponseClick = index => {
    if (onChange) {
      onChange(index);
    }
  };

  return (
    <div className={styles.review}>
      <header className={styles.reviewHeader}>
        <h2 className={styles.reviewHeaderTitle}>Confirmation</h2>
        <h3 className={styles.reviewHeaderSubtitle}>Please review your answers</h3>
      </header>

      <ul className={styles.reviewTable}>
        {questions.map((question, index) => (
          <li key={question.id} className={styles.reviewTableRow}>
            <button
              className={styles.response}
              type="button"
              onClick={() => onResponseClick(index)}
              title={`Jump to question ${index + 1}`}
              aria-label={`Jump to question ${index + 1}`}>
              <span className={styles.responseQuestion}>{question.fields['Short Question']}</span>
              <span className={styles.hide}>Your answer was</span>
              <span className={styles.responseAnswer}>{responses[question.id] ? 'Yes' : 'No'}</span>
            </button>
          </li>
        ))}
      </ul>

      <p className={styles.reviewNotice}>
        Don’t worry&mdash;all answers are anonymous and no data is stored or shared.
      </p>
    </div>
  );
};

Review.propTypes = {
  onChange: PropTypes.func,
  questions: PropTypes.array.isRequired,
  responses: PropTypes.object.isRequired,
};

export default Review;
