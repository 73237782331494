import React from 'react';
import PropTypes from 'prop-types';
import styles from './loader.module.scss';
import { className } from '@src/utils';

const Loader = ({ className: customClassName, label }) => (
  <div {...className(styles.wrapper, customClassName)}>
    <div className={styles.loader} />
    {label && <p className={styles.label}>{label}</p>}
  </div>
);

Loader.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
};

Loader.defaultProps = {
  label: 'Loading...',
};

export default Loader;
