import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from 'markdown-to-jsx';
import { Arrow } from '@src/svgs';
import { className } from '@src/utils';
import styles from './details.module.scss';

const QuestionDetails = ({ id, details }) => {
  const [showDetails, setShowDetails] = useState(false);
  const contentRef = useRef(null);

  const handleToggle = e => {
    e.preventDefault();
    setShowDetails(!showDetails);
  };

  useEffect(() => {
    if (showDetails) {
      contentRef.current.focus();
    }
  }, [showDetails]);

  const detailContent = details?.trim();

  if (!detailContent) {
    return null;
  }

  const isDetailsList = detailContent.match('- ');
  if (isDetailsList) {
    return (
      <div className={styles.questionDetailsHeader}>
        <Markdown options={{ forceBlock: true }}>{detailContent}</Markdown>
      </div>
    );
  }

  return (
    <div className={styles.questionDetailsHeader}>
      <div
        id={`content-${id}`}
        className={styles.questionDetailsContent}
        ref={contentRef}
        role="region"
        tabIndex={-1}
        aria-hidden={!showDetails}>
        <Markdown options={{ forceBlock: true }}>{detailContent}</Markdown>
      </div>

      <button
        id={`expand-${id}`}
        aria-controls={`content-${id}`}
        aria-expanded={showDetails}
        className={styles.questionDetailsToggle}
        onClick={handleToggle}>
        <span
          {...className(
            styles.questionDetailsToggleIcon,
            showDetails && styles.questionDetailsToggleIconOpen,
          )}>
          <Arrow />
        </span>
        {showDetails ? 'Explain less' : 'Explain more'}
      </button>
    </div>
  );
};

QuestionDetails.propTypes = {
  id: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
};

export default QuestionDetails;
