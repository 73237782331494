import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useState } from '@upstatement/react-hooks';
import { STORAGE_KEYS } from '@src/utils/constants';
import { KioskModeContext, OnboardingContext } from '@src/utils/contexts';

const OnboardingProvider = ({ children }) => {
  const kioskMode = useContext(KioskModeContext);

  const [hasOnboarded, setHasOnboarded] = useState(() => {
    if (kioskMode.isEnabled) {
      return true;
    }
    const storedValue = JSON.parse(localStorage.getItem(STORAGE_KEYS.hasOnboarded));
    return storedValue || false;
  });

  const [isOnboardingOpen, setIsOnboardingOpen] = useState(() => !hasOnboarded);

  const updateHasOnboarded = nextHasOnboarded => {
    setHasOnboarded(nextHasOnboarded);
    localStorage.setItem(STORAGE_KEYS.hasOnboarded, nextHasOnboarded);
  };

  return (
    <OnboardingContext.Provider
      value={{
        isOnboardingOpen,
        setIsOnboardingOpen,
        hasOnboarded,
        setHasOnboarded: updateHasOnboarded,
      }}>
      {children}
    </OnboardingContext.Provider>
  );
};

OnboardingProvider.propTypes = {
  children: PropTypes.node,
};

export default OnboardingProvider;
