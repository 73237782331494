import React from 'react';
import PropTypes from 'prop-types';
import { useState } from '@upstatement/react-hooks';
import { getModalNavRoutes } from '@src/components/ModalNav/ModalNavContent';
import { ModalNavContext } from '@src/utils/contexts';

const ModalNavProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const getPaths = () => getModalNavRoutes().map(({ path }) => path);

  return (
    <ModalNavContext.Provider
      value={{
        isOpen,
        setIsOpen,
        getPaths,
      }}>
      {children}
    </ModalNavContext.Provider>
  );
};

ModalNavProvider.propTypes = {
  children: PropTypes.node,
};

export default ModalNavProvider;
