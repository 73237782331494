import React, { useContext, useRef, forwardRef, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import Announcer from '@src/components/Announcer';
import Transition from '@src/components/Transition';
import useCreateAnnouncer from '@src/hooks/useCreateAnnouncer';
import useSlidingTransition from '@src/hooks/useSlidingTransition';
import About from '@src/pages/About';
import AddToHome from '@src/pages/AddToHome';
import HowItWorks from '@src/pages/HowItWorks';
import Menu from '@src/pages/Menu';
import PrivacyPolicy from '@src/pages/PrivacyPolicy';
import Support from '@src/pages/Support';
import { className } from '@src/utils';
import { ModalNavContext, ModalNavHistoryContext } from '@src/utils/contexts';
import styles from './modalNavContent.module.scss';

export const getModalNavRoutes = () => [
  {
    path: '/menu',
    component: Menu,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/how-it-works',
    component: HowItWorks,
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/support',
    component: Support,
  },
  {
    path: '/add-to-home',
    component: AddToHome,
  },
];

const ModalNavContent = forwardRef((props, modalNavContentRef) => {
  const { isOpen } = useContext(ModalNavContext);

  const modalNavHistory = useContext(ModalNavHistoryContext);
  const location = useLocation();

  const onMenuPageRef = useRef(true);

  const announcer = useCreateAnnouncer('modal-nav');

  const ScreenTransitions = useSlidingTransition({
    getTranslateX(isEntering) {
      if (isEntering) {
        return onMenuPageRef.current ? -10 : 10;
      }
      return onMenuPageRef.current ? 10 : -10;
    },
    onEntered(screen, isAppearing) {
      if (!isAppearing) {
        // Reset focus when the page changes
        modalNavContentRef.current.focus();
      }
    },
    onExited() {
      // Reset scroll position when screen changes
      modalNavContentRef.current.scrollTo(0, 0);
    },
  });

  const onOpen = () => {
    const unlistenToModalNavHistory = modalNavHistory.listen(location => {
      onMenuPageRef.current = location.pathname === '/menu';
    });
    return unlistenToModalNavHistory;
  };

  const onClose = () => {
    // Reset navigation for next time
    announcer.reset();
  };

  useEffect(() => {
    if (isOpen) {
      return onOpen();
    } else {
      return onClose();
    }
  }, [isOpen]);

  return (
    <Announcer announcer={announcer}>
      <div
        ref={modalNavContentRef}
        {...className(
          styles.modalNavMain,
          ScreenTransitions.isTransitioning && styles.modalNavMainWithTransition,
        )}
        tabIndex={-1}>
        {isOpen && (
          <TransitionGroup component={null}>
            <Transition
              key={location.pathname}
              appear
              unmountOnExit
              timeout={ScreenTransitions.timeout}
              onEnter={ScreenTransitions.onEnter}
              onExit={ScreenTransitions.onExit}>
              <div className={styles.modalNavMainContent}>
                <Switch location={location}>
                  {getModalNavRoutes().map(({ path, component }) => (
                    <Route key={path} path={path} exact component={component} />
                  ))}
                </Switch>
              </div>
            </Transition>
          </TransitionGroup>
        )}
      </div>
    </Announcer>
  );
});

ModalNavContent.displayName = 'ModalNavContent';

export default ModalNavContent;
