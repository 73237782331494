import React from 'react';
import PropTypes from 'prop-types';
import { className } from '@src/utils';
import styles from './pagination.module.scss';

const Pagination = ({ current, light, max }) => (
  <ul className={styles.paginationList} aria-label={`Page ${current + 1} of ${max}`}>
    {Array.apply(null, Array(max)).map((_, index) => (
      <li key={index} className={styles.paginationListItem}>
        <div
          {...className(
            styles.dot,
            current === index && styles.dotActive,
            light && styles.dotLight,
          )}
        />
      </li>
    ))}
  </ul>
);

Pagination.propTypes = {
  current: PropTypes.number,
  light: PropTypes.bool,
  max: PropTypes.number.isRequired,
};

Pagination.defaultProps = {
  current: -1,
  light: false,
};

export default Pagination;
