import React from 'react';
import PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import FormDataProvider from '@src/providers/FormDataProvider';
import FullHeightProvider from '@src/providers/FullHeightProvider';
import HistoryProvider from '@src/providers/HistoryProvider';
import KioskModeProvider from '@src/providers/KioskModeProvider';
import ModalNavProvider from '@src/providers/ModalNavProvider';
import OnboardingProvider from '@src/providers/OnboardingProvider';
import PassportProvider from '@src/providers/PassportProvider';
import ServiceWorkerProvider from '@src/providers/ServiceWorkerProvider';
import SplashScreenProvider from '@src/providers/SplashScreenProvider';
import SpotColorsProvider from '@src/providers/SpotColorsProvider';

const ProviderGroup = ({ children }) => (
  <HistoryProvider>
    <ServiceWorkerProvider>
      <KioskModeProvider>
        <OnboardingProvider>
          <ModalNavProvider>
            <HelmetProvider>
              <PassportProvider>
                <FullHeightProvider>
                  <SplashScreenProvider>
                    <SpotColorsProvider>
                      <FormDataProvider>{children}</FormDataProvider>
                    </SpotColorsProvider>
                  </SplashScreenProvider>
                </FullHeightProvider>
              </PassportProvider>
            </HelmetProvider>
          </ModalNavProvider>
        </OnboardingProvider>
      </KioskModeProvider>
    </ServiceWorkerProvider>
  </HistoryProvider>
);

ProviderGroup.propTypes = {
  children: PropTypes.node,
};

export default ProviderGroup;
