import React from 'react';
import styles from '@src/svgs/sharedStyles.module.scss';

const Arrow = () => (
  <svg
    className={styles.root}
    viewBox="0 0 9 14"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true">
    <path
      className={styles.fill}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.61152 1L8.0914 2.34535L3.70292 7.17267L8.0914 12L6.61152 13.3453L0.999999 7.17267L6.61152 1Z"
    />
  </svg>
);

export default Arrow;
