import React, { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';
import { className } from '@src/utils';
import styles from './image.module.scss';

const Image = forwardRef(({ className: customClassName, src, alt, ...props }, ref) => {
  const [loaded, setLoaded] = useState(() => {
    const tempImage = new window.Image();
    tempImage.src = src;
    // Returns whether the image has already been previous loaded
    return tempImage.complete;
  });

  const onLoad = () => {
    setLoaded(true);
  };

  return (
    <img
      ref={ref}
      {...className(styles.image, loaded && styles.imageLoaded, customClassName)}
      src={src}
      alt={alt}
      onLoad={onLoad}
      {...props}
    />
  );
});

Image.displayName = 'Image';

Image.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default Image;
