import React, { useContext } from 'react';
import Link from '@src/components/Link';
import SEO from '@src/components/SEO';
import useAnnounce from '@src/hooks/useAnnounce';
import { FormDataContext, PassportContext } from '@src/utils/contexts';
import { STORAGE_KEYS } from '@src/utils/constants';
import styles from './howItWorks.module.scss';

const STEPS = [
  {
    title: 'Get your day pass',
    description:
      'Before leaving for work, answer a few quick questions to show that you’re healthy. You’ll receive a day pass for entry to the office.',
  },
  {
    title: 'Show to security',
    description:
      'Hang on to your day pass and be prepared to display each time you enter the building.',
  },
  {
    title: 'Work with peace of mind',
    description:
      'Everyone who enters gets a pass first. We all share a responsibility to protect not only ourselves but each other.',
  },
];

const HowItWorks = () => {
  const { hasStartedSurvey } = useContext(FormDataContext);
  const { hasPassport } = useContext(PassportContext);

  useAnnounce(() => 'How It Works');

  const selectedBuilding = localStorage.getItem(STORAGE_KEYS.selectedBuilding);

  const linkToShow = () => {
    if (hasPassport && selectedBuilding) {
      return (
        <Link className={styles.checkInBtn} to={`/buildings/${selectedBuilding}/pass`}>
          Get Your <span>Pass</span>
        </Link>
      );
    } else if (selectedBuilding) {
      return (
        <Link className={styles.checkInBtn} to={`/buildings/${selectedBuilding}/questionnaire`}>
          {hasStartedSurvey ? 'Resume' : 'Start'} <span>Check-in</span>
        </Link>
      );
    }

    return (
      <Link className={styles.checkInBtn} to="/buildings">
        Select <span>Building</span>
      </Link>
    );
  };

  return (
    <section className={styles.page}>
      <SEO title="How It Works" />
      <div className={styles.pageInner}>
        <header>
          <h1 className={styles.pageLabel}>How it works</h1>
          <h2 className={styles.pageTitle}>Easy as 1, 2, 3...</h2>
        </header>

        <ol className={styles.stepList}>
          {STEPS.map(({ description, title }) => (
            <li key={title} className={styles.stepListItem}>
              <h2 className={styles.stepListItemTitle}>{title}</h2>
              <p>{description}</p>
            </li>
          ))}
        </ol>

        {linkToShow()}
      </div>
    </section>
  );
};

export default HowItWorks;
