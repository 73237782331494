import { createContext } from 'react';

export const AnnouncerContext = createContext({
  /** @type {string | null} */
  screenTitle: null,
  /** @type {(nextScreenTitle: string) => void} */
  onScreenChange: () => {},
  reset: () => {},
});

export const FormDataContext = createContext(null);

/** @type {import('react').Context<{ value: number | null, willUpdate: boolean }>} */
export const FullHeightContext = createContext({ value: null, willUpdate: false });

/** @type {React.Context<import('history').History<{}> | null>} */
export const GlobalHistoryContext = createContext(null);

export const KioskModeContext = createContext({
  isEnabled: false,
  searchQuery: '',
  restart: () => {},
});

/** @type {React.Context<import('history').History<{}> | null>} */
export const ModalNavHistoryContext = createContext(null);

/** @type {import('react').Context<{ isOpen: boolean, setIsOpen(nextIsOpen: boolean): void, getPaths(): string[] }>} */
export const ModalNavContext = createContext({
  isOpen: false,
  setIsOpen: () => {},
  getPaths: () => [],
});

/** @type {import('react').Context<{ isOnboardingOpen: boolean, setIsOnboardingOpen(nextIsOnboardingOpen: boolean): void, hasOnboarded: boolean, setHasOnboarded(nextHasOnboarded: boolean): void }>} */
export const OnboardingContext = createContext({
  isOnboardingOpen: false,
  setIsOnboardingOpen: () => {},
  hasOnboarded: false,
  setHasOnboarded: () => {},
});

/** @type {import('react').Context<import('@src/providers/PassportProvider').PassportContextValue>} */
export const PassportContext = createContext({
  success: null,
  timestamp: null,
  hasPassport: false,
  set: () => {},
  remove: () => {},
});

export const ServiceWorkerContext = createContext({ isUpdating: false });

/** @type {import('react').Context<[{ dimensions: DOMRect | null, hasRunFor: string[], isVisible: boolean, text: string }, function]>} */
export const SplashScreenContext = createContext([
  { dimensions: null, hasRunFor: [], isVisible: false, text: '' },
  () => {},
]);

/** @type {import('react').Context<{ spotColors: { main: string | null, secondary: string | null, isLight: boolean }, setSpotColors: function, resetSpotColors: function }>} */
export const SpotColorsContext = createContext({
  spotColors: { main: null, secondary: null, isLight: false },
  setSpotColors: () => {},
  resetSpotColors: () => {},
});

/** @type {import('react').Context<['entering'|'entered'|'exiting'|'exited'|null, boolean]} */
export const TransitionContext = createContext([null, false]);
