import React from 'react';
import Link from '@src/components/Link';
import SEO from '@src/components/SEO';
import useAnnounce from '@src/hooks/useAnnounce';
import { Arrow } from '@src/svgs';
import styles from './about.module.scss';

const About = () => {
  useAnnounce(() => 'About');

  return (
    <section className={styles.page}>
      <SEO title="About" />
      <div className={styles.pageInner}>
        <header>
          <h1 className={styles.pageLabel}>About</h1>
          <h2 className={styles.title}>
            <span>You work here.</span>
            We work to keep you healthy and safe here.
          </h2>
        </header>
        <div className={styles.freeText}>
          <p>
            When you go into work you probably don’t think about who owns the building. But the
            Beacon Capital Partners team is working every day to provide you with a safe and
            seamless workplace experience.
          </p>
          <p>
            As your building owner, the health and safety of you, your colleagues, and the broader
            building community is of the highest importance to us. To this end, Beacon is
            implementing measures based on the best available science today to provide for the
            safety and well-being of everyone who enters one of our buildings.
          </p>
          <p>
            This app is one part of a multi-faceted approach to maintain and operate a safe and
            healthy workplace. Rest assured, whenever you’re ready to come back to the office we’ll
            be ready, too.
          </p>

          <h3>How it works</h3>
          <p>
            Just answer a few quick safety questions to unlock your day pass. Show it to security
            and you’ll be on your way. Easy as that.
          </p>
          <p>
            Everyone who enters the building goes through the same process. We all share a
            responsibility to protect not only ourselves but each other.
          </p>
          <Link to="/how-it-works">
            More about how it works
            <span>
              <Arrow />
            </span>
          </Link>

          <h3>Built around privacy</h3>
          <p>
            We believe that safety and privacy go hand in hand. This application doesn’t associate
            any personal identifying information with health data. All answers are anonymous and no
            health data is stored or saved.
          </p>
          <Link to="/privacy-policy">
            Full privacy policy
            <span>
              <Arrow />
            </span>
          </Link>

          <h3>About Beacon Capital</h3>
          <p>
            Beacon Capital Partners is a tenant-focused private real estate investment firm with a
            75-year legacy of successful real estate development, ownership, and management.
          </p>
          <p>
            Beacon invests in high-quality office properties in core urban markets with
            highly-educated workforces. Our hands-on approach transforms these properties for
            today’s tenants, with innovative design, state-of-the-art connectivity, modern
            amenities, and award-winning sustainability and risk management initiatives. From
            Fortune 100 firms to the leading innovative and growth companies, our tenants are proud
            to call our distinctive workplaces home.
          </p>
          <a href="https://www.beaconcapital.com/">
            More about Beacon
            <span>
              <Arrow />
            </span>
          </a>
        </div>
        <p className={styles.copyright}>
          &copy; 2020, Beacon Capital Partners, LLC. <span>All rights reserved.</span>
        </p>
      </div>
    </section>
  );
};

export default About;
