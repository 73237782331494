import React from 'react';
import styles from '@src/svgs/sharedStyles.module.scss';

const AddToHome = () => (
  <svg className={styles.root} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      className={styles.stroke}
      x="0.5"
      y="0.5"
      width="16"
      height="16"
      rx="3.5"
      strokeLinecap="round"
    />
    <line
      className={styles.stroke}
      x1="4.54761"
      y1="8.40479"
      x2="12.4524"
      y2="8.40479"
      strokeLinecap="round"
    />
    <line
      className={styles.stroke}
      x1="8.40479"
      y1="12.4524"
      x2="8.40479"
      y2="4.54763"
      strokeLinecap="round"
    />
  </svg>
);

export default AddToHome;
