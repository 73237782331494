import BuildingData from '@src/data/locations.json';

/**
 *
 * @param {String} name | Name of building
 *
 * @return Building object
 */
const getBuildingByName = name => {
  try {
    return BuildingData.find(building => building.fields.Name === name);
  } catch (e) {
    console.log(e);
  }
};

export default getBuildingByName;
