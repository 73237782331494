/**
 *
 * @param {Date} date | Date to parse
 *
 * @returns an array with the month and day for a given date
 */
export default function getMonthAndDay(date) {
  // Gets the date, in the format M.DD
  const month = date.getUTCMonth() + 1;
  let monthDay = date.getUTCDate().toString();
  if (monthDay.length === 1) {
    monthDay = `0${monthDay}`;
  }

  return [month, monthDay];
}
