export const DEFAULT_SPLASH_ID = 'anonymous';

export const IS_BROWSER = Object.freeze({
  chrome:
    (/(criOS)/i.test(navigator.userAgent) || /(chrome)/i.test(navigator.userAgent)) &&
    !/(chromium)/i.test(navigator.userAgent) &&
    !/(SamsungBrowser)/i.test(navigator.userAgent),
  safari:
    /(safari)/i.test(navigator.userAgent) &&
    !/(criOS)/i.test(navigator.userAgent) &&
    !/(chrome)/i.test(navigator.userAgent) &&
    !/(chromium)/i.test(navigator.userAgent),
});

export const IS_DEVICE = Object.freeze({
  iOS: !!navigator.platform.match(/iPhone|iPod|iPad/),
  android: /(android)/i.test(navigator.userAgent),
});

export const STORAGE_KEYS = Object.freeze({
  success: 'passport_success',
  timestamp: 'passport_timestamp',
  lastPrompt: 'last_prompt_time',
  serviceWorkerUpdated: 'service_worker_updated',
  selectedBuilding: 'selected_building',
  hasOnboarded: 'has_seen_onboarding',
});
