import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FormDataContext } from '@src/utils/contexts';

const FormDataProvider = ({ children }) => {
  const [questions, setQuestions] = useState([]);
  const [formData, setFormData] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [hasFinished, setHasFinished] = useState(false);

  const stepsRef = useRef({ currentStep: 0, lastStep: 0 });

  /**
   * Updates the `currentStep` state variable with the given step, as well as
   * the `stepsRef` for immediate use in animations/transitions.
   *
   * @param {number} nextStep the next step state to update to
   */
  const updateStep = nextStep => {
    stepsRef.current.lastStep = stepsRef.current.currentStep;
    stepsRef.current.currentStep = nextStep;
    setCurrentStep(nextStep);
  };

  /**
   * Checks if the user has finished responding to the form, or if there are
   * more questions needing answers.
   */
  const checkIfFinished = () => {
    const questionIds = questions.map(question => question.id);

    if (questionIds.length < 1) {
      return false;
    }

    return !questionIds.some(id => !Object.prototype.hasOwnProperty.call(formData, id));
  };

  /**
   * Updates the form data to reflect the user's lastest response, then advances
   * to the next question.
   *
   * @param {string} key the ID of the question
   * @param {boolean} value the user's response to the question
   */
  const updateData = (key, value, isUpdatingResponse = false) => {
    formData[key] = value;
    setFormData(formData);
    setHasFinished(checkIfFinished());

    if (isUpdatingResponse) {
      // If they're updating their response to this question,
      // take them back to review screen
      updateStep(questions.length);
    } else {
      // Otherwise, go to next question
      updateStep(currentStep + 1);
    }
  };

  const providerData = {
    questions,
    setQuestions,
    hasFinished,
    currentStep,
    formData,
    updateData,
    setCurrentStep: updateStep,
    stepsRef,
    hasStartedSurvey: currentStep > 0,
  };

  return <FormDataContext.Provider value={providerData}>{children}</FormDataContext.Provider>;
};

FormDataProvider.propTypes = {
  children: PropTypes.node,
};

export default FormDataProvider;
