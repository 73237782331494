import { useContext } from 'react';
import { DEFAULT_SPLASH_ID } from '@src/utils/constants';
import { PassportContext, SplashScreenContext } from '@src/utils/contexts';

/**
 * Custom React hook that checks whether a splash screen animation could and
 * should be run for the given screen ID.
 *
 * @param {string} splashId the unique screen ID to check against
 *
 * @returns {boolean} whether to show the splash screen for the given screen ID
 */
const useSplash = splashId => {
  const { isDataFromSession } = useContext(PassportContext);

  const [{ hasRunFor }] = useContext(SplashScreenContext);

  const shouldShowSplash = !hasRunFor.some(runId => {
    if (splashId === runId) {
      // If any of the given screens have already shown their splash,
      // don't show it again
      return true;
    } else if (isDataFromSession) {
      // If passport data is from session, the splash screen can be shown
      // (For form completion transitions)
      return false;
    }
    // Otherwise, check if any splash screen has been shown at all
    return runId === DEFAULT_SPLASH_ID;
  });

  return shouldShowSplash;
};

export default useSplash;
