import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import BuildingHome from '@src/pages/BuildingHome';
import Locations from '@src/pages/Locations';
import NotFound from '@src/pages/NotFound';
import Passport from '@src/pages/Passport';
import Questionnaire from '@src/pages/Questionnaire';
import { STORAGE_KEYS } from '@src/utils/constants';
import { KioskModeContext, ModalNavContext } from '@src/utils/contexts';

const Routes = ({ location }) => {
  const kioskMode = useContext(KioskModeContext);
  const { getPaths: getModalPaths } = useContext(ModalNavContext);

  const selectedBuilding = localStorage.getItem(STORAGE_KEYS.selectedBuilding);
  const buildingRoute = selectedBuilding ? `/buildings/${selectedBuilding}/` : '/buildings';

  // Location is passed to control the Switch state.
  // @see https://github.com/ReactTraining/react-router/issues/4351#issuecomment-281196606
  return (
    <Switch location={location}>
      <Redirect exact from="/" to={buildingRoute + kioskMode.searchQuery} />

      {getModalPaths().map(path => (
        <Route key={path} path={path} exact render={null} />
      ))}

      <Route component={Locations} exact path="/buildings" />
      <Route component={BuildingHome} exact path="/buildings/:locationName" />
      <Route component={Questionnaire} exact path="/buildings/:locationName/questionnaire" />
      <Route component={Passport} exact path="/buildings/:locationName/pass" />
      <Route component={NotFound} path="*" />
    </Switch>
  );
};

Routes.propTypes = {
  location: PropTypes.object.isRequired,
};

export default Routes;
