import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from '@upstatement/react-hooks';
import { STORAGE_KEYS } from '@src/utils/constants';
import { PassportContext } from '@src/utils/contexts';
import getMonthAndDay from '@src/utils/dateHelper';

/**
 * @typedef {{
 * success: boolean | null,
 * timestamp: number | null,
 * isDataFromSession: boolean,
 * hasPassport: boolean,
 * set(success: boolean, timestamp: number): void,
 * remove(): void
 * }} PassportContextValue
 */

const PassportProvider = ({ children }) => {
  const [timestamp, setTimestamp] = useState(() =>
    JSON.parse(localStorage.getItem(STORAGE_KEYS.timestamp)),
  );
  const [success, setSuccess] = useState(() =>
    JSON.parse(localStorage.getItem(STORAGE_KEYS.success)),
  );

  const [isDataFromSession] = useState(() => success === null || timestamp === null);

  /**
   * Updates the passport values and saves them to local storage.
   *
   * @param {boolean} success whether or not there was a successful form submission
   * @param {number} timestamp the number timestamp when the form was taken
   */
  const set = (success, timestamp) => {
    localStorage.setItem(STORAGE_KEYS.success, success);
    localStorage.setItem(STORAGE_KEYS.timestamp, timestamp);

    setSuccess(success);
    setTimestamp(timestamp);
  };

  /**
   * Removes the current passport values, and deletes them from local storage.
   */
  const remove = () => {
    localStorage.removeItem(STORAGE_KEYS.success);
    localStorage.removeItem(STORAGE_KEYS.timestamp);

    setSuccess(null);
    setTimestamp(null);
  };

  useEffect(() => {
    if (success !== null && timestamp) {
      // Gets the stored date object
      const date = new Date(timestamp);
      const today = new Date();

      const [originalMonth, originalDay] = getMonthAndDay(date);
      const [currentMonth, currentDay] = getMonthAndDay(today);

      if (originalMonth !== currentMonth || originalDay !== currentDay) {
        remove();
      }
    }
  }, [success, timestamp]);

  const hasPassport = success !== null && !!timestamp;

  return (
    <PassportContext.Provider
      value={{ success, timestamp, isDataFromSession, hasPassport, set, remove }}>
      {children}
    </PassportContext.Provider>
  );
};

PassportProvider.propTypes = {
  children: PropTypes.node,
};

export default PassportProvider;
