import React from 'react';
import SEO from '@src/components/SEO';
import styles from './notFound.module.scss';

const NotFound = () => (
  <section className={styles.page}>
    <SEO title="Not found" />
    <div className={styles.pageInner}>
      <h1>Page not found.</h1>
    </div>
  </section>
);

export default NotFound;
