import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import Link from '@src/components/Link';
import Image from '@src/components/Image';
import { className } from '@src/utils';
import { KioskModeContext, PassportContext } from '@src/utils/contexts';
import styles from './buildings.module.scss';

const Buildings = ({ region, filteredLocations }) => {
  const { hasPassport } = useContext(PassportContext);
  const kioskMode = useContext(KioskModeContext);

  const getLink = selectedBuilding => {
    if (hasPassport) {
      return `/buildings/${selectedBuilding}/pass${kioskMode.searchQuery}`;
    }
    return `/buildings/${selectedBuilding}${kioskMode.searchQuery}`;
  };

  return (
    <Fragment>
      <header>
        <h1 className={styles.locationsTitle}>{region}</h1>
        <h2 className={styles.locationsQuestion}>Where are you checking&nbsp;in&nbsp;today?</h2>
      </header>
      <div className={styles.buildingList}>
        {filteredLocations.map(loc => (
          <Link
            {...className(styles.buildingLink, styles.buildingListItem)}
            key={loc.id}
            to={getLink(loc.fields.Name)}>
            <figure className={styles.buildingsImageWrapper}>
              <div className={styles.buildingsImageWrapperInner}>
                <Image
                  className={styles.buildingLinkImage}
                  src={loc.fields.Image[0].thumbnails.large.url}
                  alt={loc.fields.Name}
                  aria-hidden={true}
                />
              </div>
              <figcaption className={styles.buildingLinkText}>{loc.fields.Name}</figcaption>
            </figure>
          </Link>
        ))}
      </div>
    </Fragment>
  );
};

Buildings.propTypes = {
  filteredLocations: PropTypes.array,
  region: PropTypes.string,
};

export default Buildings;
