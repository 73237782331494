import React from 'react';
import PropTypes from 'prop-types';
import { AnnouncerContext } from '@src/utils/contexts';
import styles from './announcer.module.scss';

const Announcer = ({ announcer, children }) => (
  <AnnouncerContext.Provider value={announcer}>
    {children}
    {announcer.screenTitle && (
      <div
        id={`${announcer.id}-announcer`}
        className={styles.announcer}
        aria-live="polite"
        aria-atomic="true">
        Navigated to {announcer.screenTitle}
      </div>
    )}
  </AnnouncerContext.Provider>
);

Announcer.propTypes = {
  announcer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    screenTitle: PropTypes.string,
    onScreenChange: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
  }),
  children: PropTypes.node,
};

export default Announcer;
