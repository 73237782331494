import React, { useContext, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Arrow } from '@src/svgs';
import { className } from '@src/utils';
import { IS_DEVICE } from '@src/utils/constants';
import { KioskModeContext } from '@src/utils/contexts';
import styles from './passportCta.module.scss';

const RESET_COUNTDOWN = 15;

const PassportCta = ({ bleed }) => {
  const ctaRef = useRef(null);

  const { android } = IS_DEVICE;
  const PWA_LINK = android
    ? 'https://play.google.com/store/apps/details?id=com.beaconcp.screener&hl=en_US'
    : 'https://apps.apple.com/us/app/id1527096004';

  const kioskMode = useContext(KioskModeContext);

  const timerIntervalRef = useRef(null);
  const [countdown, setCountdown] = useState(RESET_COUNTDOWN + 1);

  const startCountdown = () => {
    timerIntervalRef.current = setInterval(() => {
      setCountdown(countdown => countdown - 1);
    }, 1000);
  };

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(timerIntervalRef.current);
      kioskMode.restart();
    }
  }, [countdown]);

  useEffect(() => {
    if (kioskMode.isEnabled) {
      const timeout = setTimeout(startCountdown, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [kioskMode.isEnabled]);

  const promptLinks = (
    <div className={styles.ctaInner}>
      <a
        href={PWA_LINK}
        rel="noopener noreferrer"
        target="_blank"
        className={styles.ctaDownloadLink}>
        <img src="/apple-touch-icon.png" className={styles.ctaAppIcon} alt="App icon" />
        <span className={styles.ctaLinkText}>Download</span>
      </a>
      <a
        href={PWA_LINK}
        rel="noopener noreferrer"
        target="_blank"
        className={styles.ctaInnerContent}>
        <span>Download the app</span>
        Get into the office faster.
      </a>
    </div>
  );

  const kioskRestartButtons = (
    <div className={styles.ctaInner}>
      <button
        {...className(styles.ctaLink, countdown <= RESET_COUNTDOWN && styles.ctaLinkWithOverlay)}
        onClick={kioskMode.restart}
        title="Restart">
        <span className={styles.ctaLinkCountdown} aria-hidden="true">
          {countdown}
        </span>
        <i className={styles.ctaLinkIcon}>
          <Arrow />
        </i>
        <span className={styles.ctaLinkText}>Restart kiosk</span>
      </button>
      <button className={styles.ctaInnerContent} onClick={kioskMode.restart}>
        <span>Back to home</span>
        Pass auto-expires
      </button>
    </div>
  );

  return (
    <div ref={ctaRef} {...className(styles.cta, bleed && styles.ctaBleed)} tabIndex={-1}>
      {kioskMode.isEnabled ? kioskRestartButtons : promptLinks}
    </div>
  );
};

PassportCta.propTypes = {
  bleed: PropTypes.bool,
};

PassportCta.defaultProps = {
  bleed: false,
};

export default PassportCta;
