import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** Google Analytics Measurement ID */
const MEASUREMENT_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;

/**
 * Helper function to log arguments that would've been passed to `gtag`.
 *
 * @param  {...any} args Arguments to log
 */
const gtagLog = (...args) =>
  typeof console !== 'undefined' && console.info('gtag(', args.map(JSON.stringify), ')');

/*
 * Helper to safely access global gtag instance.
 *
 * If gtag is not available it will fallback to a function that logs
 * arguments for debugging purposes.
 */
const gtag = typeof window.gtag !== 'undefined' ? window.gtag : gtagLog;

/**
 * Send pageview event to Google Analytics.
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 *
 * @param {string} path
 */
const sendPageview = params => gtag('config', MEASUREMENT_ID, params);

/**
 * Send event command to Google Analytics.
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events
 *
 * @param {string} action Event action.
 * @param {object} params Event parameters.
 * @param {string} params.category Event category.
 * @param {string} params.label Event label.
 * @param {string} params.value Event value.
 */
export const sendEvent = (action, params) => gtag('event', action, params);

/**
 * Hook to send Google Analytics pageviews when browser location changes.
 *
 * Note: This is run on every route change, inclusive of the initial route.
 * To avoid duplicate pageviews for initial page load we set `send_page_view`
 * to false when sending the initial `config` command.
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs#disable_pageview_measurement
 */
export function usePageView() {
  const location = useLocation();

  useEffect(() => {
    sendPageview({
      page_title: document.title,
      page_path: location.pathname,
    });
  }, [location]);
}
