import QuestionData from '@src/data/question-data.json';

/**
 *
 * @param {String} name | Name of building
 *
 * @return Question collection
 */
const getQuestionsForBuilding = name => {
  try {
    return QuestionData.find(questions => questions.name === name)?.data;
  } catch (e) {
    console.log(e);
  }
};

export default getQuestionsForBuilding;
