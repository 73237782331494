import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useState } from '@upstatement/react-hooks';
import { IS_DEVICE } from '@src/utils/constants';
import { FullHeightContext } from '@src/utils/contexts';

const FullHeightProvider = ({ children }) => {
  const [fullHeight, setFullHeight] = useState(null);
  /**
   * Updates the `--full-height` CSS variable value to the inner window height on
   * window resize.
   *
   * This functionality should only occur on iOS where 100vh is not the actual
   * height of the window do to their navigation bars.
   */
  const onResize = () => {
    const updatedFullHeight = window.innerHeight;
    setFullHeight(updatedFullHeight);
    document.documentElement.style.setProperty('--full-height', `${updatedFullHeight}px`);
  };

  useEffect(() => {
    if (IS_DEVICE.iOS) {
      onResize();
      window.addEventListener('resize', onResize);

      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
  }, []);

  return (
    <FullHeightContext.Provider value={{ value: fullHeight, willUpdate: IS_DEVICE.iOS }}>
      {children}
    </FullHeightContext.Provider>
  );
};

FullHeightProvider.propTypes = {
  children: PropTypes.node,
};

export default FullHeightProvider;
