import React from 'react';
import SEO from '@src/components/SEO';
import useAnnounce from '@src/hooks/useAnnounce';
import styles from './support.module.scss';

const Support = () => {
  useAnnounce(() => 'Support');

  return (
    <section className={styles.page}>
      <SEO title="Support" />
      <div className={styles.pageInner}>
        <header>
          <h1 className={styles.pageLabel}>Support</h1>
          <h2 className={styles.title}>How can we help?</h2>
        </header>
        <div className={styles.freeText}>
          <p>
            Having some trouble with this app? Not to worry, we’re here to help! Just{' '}
            <a href="mailto:appsupport@beaconcapital.com">send us an email</a> or tap the blue
            button below. Someone from our team will respond shortly.
          </p>
        </div>
        <div className={styles.linkWrapper}>
          <a href="mailto:appsupport@beaconcapital.com" className={styles.contactLink}>
            Contact
          </a>
        </div>
        <p className={styles.notice}>
          Our support is limited to this application. We’re unable to assist with questions related
          to building security and operations, personal health, or other non-technical issues.
        </p>
      </div>
    </section>
  );
};

export default Support;
