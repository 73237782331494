import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import useAnnounce from '@src/hooks/useAnnounce';
import styles from './onboardingIntro.module.scss';

const OnboardingIntro = ({ onNext }) => {
  useAnnounce(() => 'Welcome');

  return (
    <Fragment>
      <header className={styles.header}>
        <h1 className={styles.headerTitle}>For your safety</h1>
        <h2 className={styles.headerDescription}>
          We&rsquo;re asking everyone to show&nbsp;that they&rsquo;re healthy before&nbsp;entering.
        </h2>
      </header>
      <ol className={styles.steps}>
        <li className={styles.stepsItem}>
          <span className={styles.stepsItemText}>Take safety survey</span>
        </li>
        <li className={styles.stepsItem}>
          <span className={styles.stepsItemText}>Get your day pass</span>
        </li>
        <li className={styles.stepsItem}>
          <span className={styles.stepsItemText}>Show to security</span>
        </li>
      </ol>
      <footer className={styles.footer}>
        <img className={styles.footerIllo} src="/img/onboarding-00.gif" alt="" />
        <div className={styles.footerCta}>
          <button className={styles.footerCtaButton} onClick={onNext}>
            Next
          </button>
        </div>
      </footer>
    </Fragment>
  );
};

OnboardingIntro.propTypes = {
  onNext: PropTypes.func.isRequired,
};

export default OnboardingIntro;
