import React, { useContext, useEffect } from 'react';
import { StopHand } from '@src/svgs';
import PassportCta from '@src/components/PassportCta';
import SEO from '@src/components/SEO';
import useHotjarPageView from '@src/hooks/useHotjarPageView';
import useSplash from '@src/hooks/useSplash';
import { SplashScreenContext, SpotColorsContext } from '@src/utils/contexts';
import styles from './failure.module.scss';

const SPOT_COLORS = {
  main: styles.varSpotMainColor,
  secondary: styles.varSpotSecondaryColor,
  isLight: JSON.parse(styles.varSpotIsLight),
};

const Failure = () => {
  useHotjarPageView('/fail');

  const [, setSplashContext] = useContext(SplashScreenContext);
  const { setSpotColors, resetSpotColors } = useContext(SpotColorsContext);

  const withSplash = useSplash(Failure.SPLASH_ID);

  useEffect(() => {
    if (withSplash) {
      setSplashContext(oldContext => {
        const updatedContext = {
          ...oldContext,
          dimensions: null,
          isVisible: true,
          hasRunFor: [...oldContext.hasRunFor, Failure.SPLASH_ID],
        };
        // Update text if this isn't the first splash screen shown
        if (oldContext.hasRunFor.length) {
          updatedContext.text = `Thank you for your answers.`;
        }
        return updatedContext;
      });
    }
  }, [withSplash]);

  useEffect(() => {
    setSpotColors(SPOT_COLORS);
    return resetSpotColors;
  }, []);

  return (
    <div className={styles.page}>
      <SEO title="Failed Evalutation" />
      <div className={styles.pageInner}>
        <div className={styles.pageStopIcon}>
          <StopHand />
        </div>
        <header className={styles.pageHeader}>
          <h1 className={styles.pageHeaderTitle}>Please do not enter the building today.</h1>
        </header>
        <ul className={styles.pageNotes}>
          <li className={styles.pageNotesItem}>
            Based on your answers, you should contact a medical professional.
          </li>
          <li className={styles.pageNotesItem}>
            For more information about coming to work, please talk to your employer.
          </li>
          <li className={styles.pageNotesItem}>You may screen yourself again in 24&nbsp;hours.</li>
        </ul>
      </div>
      <PassportCta />
    </div>
  );
};

Failure.SPLASH_ID = 'failure';

export default Failure;
