import React, { useEffect, useRef, useContext } from 'react';
import { useState } from '@upstatement/react-hooks';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import FocusLock from 'react-focus-lock';
import { TransitionGroup } from 'react-transition-group';
import Announcer from '@src/components/Announcer';
import Pagination from '@src/components/Pagination';
import Portal from '@src/components/Portal';
import Transition from '@src/components/Transition';
import OnboardingIntro from './OnboardingIntro';
import OnboardingRules from './OnboardingRules';
import useCreateAnnouncer from '@src/hooks/useCreateAnnouncer';
import useModalAnimation from '@src/hooks/useModalAnimation';
import useSlidingTransition from '@src/hooks/useSlidingTransition';
import { className } from '@src/utils';
import { OnboardingContext } from '@src/utils/contexts';
import styles from './onboarding.module.scss';

const Onboarding = () => {
  const modalRef = useRef(null);
  const contentRef = useRef(null);

  const { hasOnboarded, setHasOnboarded, isOnboardingOpen, setIsOnboardingOpen } = useContext(
    OnboardingContext,
  );

  const [showRules, setShowRules] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const announcer = useCreateAnnouncer('onboarding');

  const OnboardingAnimations = useModalAnimation({
    isAppearing: true,
    getModal: () => modalRef.current,
    getModalContent: () => contentRef.current,
    onExited: () => {
      setIsOnboardingOpen(false);
    },
  });

  const ScreenTransitions = useSlidingTransition({
    getTranslateX() {
      return showRules ? 10 : -10;
    },
    onExited() {
      // Refocus the page and scroll to the top
      contentRef.current.focus();
      window.scrollTo(0, 0);
    },
  });

  const closeModal = () => {
    setHasOnboarded(true);
    OnboardingAnimations.playExit();
  };

  const beginAgreements = () => {
    setCurrentStep(1);
    setShowRules(true);
  };

  const agreeToRule = () => {
    if (currentStep === 3) {
      closeModal();
    }
    setCurrentStep(currentStep + 1);
  };

  useEffect(() => {
    if (!hasOnboarded) {
      OnboardingAnimations.playEnter();
      disableBodyScroll(modalRef.current);

      return () => {
        enableBodyScroll(modalRef.current);
      };
    }
  }, [hasOnboarded]);

  const renderRule = () => {
    if (currentStep === 1) {
      return <OnboardingRules.WearFaceCovering onAgree={agreeToRule} />;
    } else if (currentStep === 2) {
      return <OnboardingRules.AvoidTouching onAgree={agreeToRule} />;
    }
    return <OnboardingRules.GoHome onAgree={agreeToRule} />;
  };

  return (
    <Portal to="modal-root" mounted={isOnboardingOpen}>
      <FocusLock noFocusGuards>
        <div className={styles.container}>
          <div
            className={styles.onboarding}
            role="dialog"
            aria-label="Onboarding"
            aria-modal="true">
            <Announcer announcer={announcer}>
              <div ref={modalRef} className={styles.onboardingInner}>
                <Pagination light max={4} current={currentStep} />
                <div
                  ref={contentRef}
                  {...className(
                    styles.onboardingContent,
                    ScreenTransitions.isTransitioning && styles.onboardingContentWithTransition,
                  )}
                  tabIndex={-1}>
                  <TransitionGroup component={null}>
                    <Transition
                      key={currentStep}
                      appear
                      unmountOnExit
                      timeout={ScreenTransitions.timeout}
                      onEnter={ScreenTransitions.onEnter}
                      onExit={ScreenTransitions.onExit}>
                      <div className={styles.onboardingScreen}>
                        {!showRules ? <OnboardingIntro onNext={beginAgreements} /> : renderRule()}
                      </div>
                    </Transition>
                  </TransitionGroup>
                </div>
              </div>
            </Announcer>
          </div>
        </div>
      </FocusLock>
    </Portal>
  );
};

export default Onboarding;
