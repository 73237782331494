import React from 'react';
import SEO from '@src/components/SEO';
import useAnnounce from '@src/hooks/useAnnounce';
import styles from './privacyPolicy.module.scss';

const PrivacyPolicy = () => {
  useAnnounce(() => 'Privacy Policy');

  return (
    <section className={styles.page}>
      <SEO title="Privacy Policy" />
      <div className={styles.pageInner}>
        <header>
          <h1 className={styles.pageLabel}>Privacy Policy</h1>
          <h2 className={styles.title}>All answers are anonymous and no health data is saved</h2>
        </header>
        <div className={styles.freeText}>
          <p>Last modified: July 6, 2020</p>

          <h3>Introduction</h3>
          <p>
            Beacon Capital Partners, LLC and its affiliates (<strong>&quot;Beacon&quot;</strong> or{' '}
            <strong>&quot;we&quot;</strong>), respect your privacy and are committed to protecting
            it through our compliance with this policy. This privacy policy sets out the basis on
            which we will process any personal data we collect from you, or which you provide to us
            when you register and use this wellness screening app (including all related
            documentation, the <strong>&quot;Application&quot;</strong>), or any services provided
            through it.
          </p>
          <p>
            This policy describes: (a) the types of information we may collect or that you may
            provide when you register or use the Application and (b) our practices for collecting,
            using, maintaining, protecting, and disclosing that information. This policy applies
            only to information we collect in the Application and in any email, text, and other
            electronic communications sent through or in connection with the Application.
          </p>
          <p>
            This policy DOES NOT apply to information that: (a) we collect offline or on any other
            Beacon applications or websites, including websites you may access through the
            Application or (b) you provide to or is collected by any third party. Our websites and
            applications, and these third parties may have their own privacy policies, which we
            encourage you to read before providing information on or through them.
          </p>
          <p>
            Please read this policy carefully to understand our policies and practices regarding
            your information and how we will treat it. By installing, using or otherwise accessing
            the Application from any device, mobile or otherwise, you are agreeing to abide by the
            terms and conditions of this privacy policy. If you do not agree with our policies and
            practices, do not download, register or use the Application. This policy may change from
            time to time. Your continued use of the Application after we make changes is deemed to
            be acceptance of those changes, so please check the policy periodically for updates.
          </p>

          <h3>Information We Collect From You</h3>
          <p>
            We collect information from and about users of the Application (a) directly from you
            when you provide it to us and (b) automatically when you use the Application. When you
            register to use the Application, we ask you to provide certain personal information,
            including such things as:
          </p>
          <ul>
            <li>First and last name</li>
            <li>Company affiliation</li>
          </ul>

          <h3>Automatic Information Collection</h3>
          <p>When you use the Application, it may use technology to automatically collect:</p>
          <ul>
            <li>
              <strong>Usage Details.</strong> When you access and use the Application, we may
              automatically collect certain details of your access to and use of the Application,
              the resources that you access and use on or through the Application.
            </li>
            <li>
              <strong>Location Information.</strong> We collect location information through the
              Application to provide you with Proximity Prompts when you are within 500 feet of your
              selected building. Location information is not stored in our systems. When you access
              the Application on your mobile device, we may directly collect precise geographic
              location information and sensor data via Global Positioning System (GPS), cellular
              network information, and other location-based features. We typically receive the
              latitude and longitude of your mobile device. Factors such as device and operating
              system determine the accuracy of this latitude and longitude and how closely it
              corresponds with your device’s location.If you enable Proximity Prompts and allow the
              Application to directly collect location information while the Application is running
              in the background, you will enable the Application to send you a push notification
              when you are within proximity of your building to obtain your pass. You can turn off
              location collection and Proximity Prompts through your device settings by clicking
              “Menu” then “Settings” in the Application, or by deleting the Application from your
              device.
            </li>
          </ul>

          <h3>Information Collection and Tracking Technologies</h3>
          <p>
            The technologies we use for automatic information collection may include cookies. A
            cookie is a small file placed on your mobile device. It may be possible to refuse to
            accept mobile cookies by activating the appropriate setting on your device. However, if
            you select this setting, you may not be able to access certain parts of the Application.{' '}
          </p>

          <h3>How We Use Your Information</h3>
          <p>
            We use information that we collect about you or that you provide to us, including any
            personal information, to:
          </p>
          <ul>
            <li>
              Provide you with the Application and its contents, and any other information, products
              or services that you request from us.
            </li>
            <li>Fulfill any other purpose for which you provide it</li>
            <li>
              Notify you when Application updates are available, and of changes to any products or
              services we offer or provide though it.
            </li>
          </ul>
          <p>
            The usage information we collect such as date and time of usage, preferences, and more
            helps us to improve the Application and to deliver a better and more personalized
            experience by enabling us to:
          </p>
          <ul>
            <li>Recognize you when you use the Application.</li>
          </ul>
          <p>
            We may retain information that we collect about you and usage information for so long as
            you use the Application unless we are required to retain it for a longer period of time
            to fulfill a legal requirement.
          </p>

          <h3>Disclosure of Your Information</h3>
          <p>
            In addition, we may disclose personal information that we collect or that you provide:
          </p>
          <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>
              To contractors, service providers, and other third parties we use to support our
              business. These third parties are bound by contractual obligations to keep personal
              information confidential and use it only for the purposes for which we disclose it to
              them.
            </li>
            <li>To fulfill the purpose for which you provide it.</li>
            <li>With your consent.</li>
            <li>
              To comply with any court order, law, or legal process, including to respond to any
              government or regulatory request.
            </li>
            <li>
              To enforce our rights arising from any contracts entered into between you and us,
              including any End User License Agreement.
            </li>
            <li>
              If we believe disclosure is necessary to protect the rights, property, or safety of
              Beacon Capital Partners, our customers or others.
            </li>
          </ul>

          <h3>Accessing and Correcting Your Personal Information</h3>
          <p>
            You can review and change your personal information by logging into the Application and
            visiting your account profile page.
          </p>

          <h3>Data Security</h3>
          <p>
            We use industry standard or better measures to protect all information that is stored on
            our servers and within our database. We limit the access to this information to those
            employees who need access to perform their job function such as our technical staff. If
            you have any questions about the security of the Application, please contact us at the
            address below.
          </p>
          <p>
            Unfortunately, the transmission of information via the internet and mobile platforms is
            not completely secure. Although we do our best to protect your personal information, we
            cannot guarantee the security of your personal information transmitted through the
            Application. We are not responsible for circumvention of any privacy settings or
            security measures we provide.
          </p>

          <h3>Changes to Our Privacy Policy</h3>
          <p>
            We may update our privacy policy from time to time. The date the privacy policy was last
            revised is identified at the top of the page.
          </p>
          <p>
            You are responsible for ensuring that we have an up-to-date active and deliverable phone
            number for you and for periodically viewing this privacy policy to check for any
            changes.
          </p>

          <h3>Contact Information</h3>

          <a href="mailto:website@beaconcapital.com">website@beaconcapital.com</a>
        </div>
      </div>
    </section>
  );
};
export default PrivacyPolicy;
