import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import gsap, { Cubic } from 'gsap';
import { FormDataContext, TransitionContext } from '@src/utils/contexts';
import QuestionDetails from './Details';
import styles from './question.module.scss';

const Question = ({ questionObject, onResponse }) => {
  const questionRef = useRef(null);

  const title = questionObject.fields.Title;
  const details = questionObject.fields.Details;
  const question = questionObject.fields['Question'] || questionObject.fields['Short Question'];

  const { stepsRef } = useContext(FormDataContext);
  const [state, isAppearing] = useContext(TransitionContext);

  const DURATION = 0.2;
  const QuestionAnimations = {
    playEnter() {
      const { lastStep, currentStep } = stepsRef.current;
      const translateX = lastStep > currentStep ? -25 : 25;
      gsap.fromTo(
        questionRef.current,
        DURATION,
        {
          x: isAppearing ? 0 : translateX,
        },
        {
          delay: isAppearing ? 0 : DURATION,
          ease: Cubic.easeOut,
          x: 0,
        },
      );
    },
    playExit() {
      const { lastStep, currentStep } = stepsRef.current;
      const translateX = lastStep > currentStep ? 25 : -25;
      gsap.fromTo(
        questionRef.current,
        DURATION,
        {
          x: 0,
        },
        {
          ease: Cubic.easeOut,
          x: translateX,
        },
      );
    },
  };

  useEffect(() => {
    if (state === 'entering') {
      QuestionAnimations.playEnter();
    } else if (state === 'exiting') {
      QuestionAnimations.playExit();
    }
  }, [state]);

  return (
    <div className={styles.question}>
      <header ref={questionRef} className={styles.questionHeader}>
        {title && <h2 className={styles.questionHeaderTitle}>{title}</h2>}
        <h3 className={styles.questionHeaderQuestion}>{question}</h3>
        {details && <QuestionDetails id={questionObject.id} details={details} />}
      </header>
      <div className={styles.questionOptions}>
        <button
          className={styles.questionOptionsItem}
          type="button"
          title="Yes"
          onClick={() => onResponse(true)}>
          Yes
        </button>
        <button
          className={styles.questionOptionsItem}
          type="button"
          title="No"
          onClick={() => onResponse(false)}>
          No
        </button>
      </div>
    </div>
  );
};

Question.propTypes = {
  questionObject: PropTypes.shape({
    id: PropTypes.string,
    fields: PropTypes.shape({
      Details: PropTypes.string,
      Question: PropTypes.string.isRequired,
      ['Short Question']: PropTypes.string.isRequired,
      Title: PropTypes.string,
    }),
  }),
  onResponse: PropTypes.func.isRequired,
};

export default Question;
